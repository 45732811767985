import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { HiddenProps, RowProps } from "@/components/grid";
import { Row } from "@/components/grid";
import type { PropsWithTheme } from "@/theme";

export const AbsoluteRow = styled(Row)<RowProps & PropsWithTheme>`
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			position: absolute;
			top: 0;
			left: 0;
		}
	`};
`;

export const StyledImageWrapper = styled.div`
	position: relative;
	z-index: -1;
	${({ theme: { tokens } }) => css`
		overflow: hidden;
		border-radius: ${tokens.borderRadius["3xl"]};
	`};
`;

export const Hidden = styled.div<HiddenProps>`
	${({ theme: { mq }, s, m, l }) => css`
		display: ${s ? "contents" : "none"};
		@media ${mq.m} {
			display: ${m ? "contents" : "none"};
		}

		@media ${mq.l} {
			display: ${l ? "contents" : "none"};
		}
	`};
`;
