import React from "react";

import { BorderlessInfoTextBoxes } from "@/components/boxes/borderless-info-text-boxes";
import { InfoSectionBoxes } from "@/components/boxes/info-section-boxes";
import { InfoTextBoxes } from "@/components/boxes/info-text-boxes";
import { LogosBoxes } from "@/components/boxes/logos-boxes";
import { Column, Grid, Row } from "@/components/grid";
import { DisplayType } from "@/contentful/content-model/types";
import { BackgroundColor } from "@/theme";
import type { Boxes as BoxesProps, Images, IconText, InfoText } from "@/types/contentful-api";

import { Background, SlotHeader } from "../components";

const typenameMapping = {
	[DisplayType.logos]: "Images",
	[DisplayType.iconText]: "IconText",
	[DisplayType.infoSection]: "InfoText",
	[DisplayType.borderlessIconText]: "IconText",
};

export const BoxesSlot: React.FC<BoxesProps> = props => {
	const {
		headline,
		description,
		backgroundColor,
		wave,
		centered,
		displayType,
		itemsCollection: { items },
	} = props;
	const isDark = backgroundColor === BackgroundColor.black;

	// Editors can add any allowed type, so we need to filter out invalid entries for DisplayType's
	const filteredItems = items.filter(item => item?.__typename === typenameMapping[displayType]);

	return (
		<Background backgroundColor={BackgroundColor[backgroundColor]} wave={wave}>
			<Grid>
				<Row>
					<Column>
						<SlotHeader
							headline={headline}
							subtitle={description}
							centered={centered}
						/>

						{displayType === DisplayType.logos && (
							<LogosBoxes items={filteredItems as Images[]} />
						)}
						{displayType === DisplayType.iconText && (
							<InfoTextBoxes items={filteredItems as IconText[]} />
						)}
						{displayType === DisplayType.infoSection && (
							<InfoSectionBoxes items={filteredItems as InfoText[]} dark={isDark} />
						)}
						{displayType === DisplayType.borderlessIconText && (
							<BorderlessInfoTextBoxes
								items={filteredItems as IconText[]}
								dark={isDark}
							/>
						)}
					</Column>
				</Row>
			</Grid>
		</Background>
	);
};
