import React from "react";

import Image from "next/image";

export const Illustration = ({ illustration }: { illustration: string }) => {
	return (
		<Image
			unoptimized
			alt=""
			height="70"
			width="70"
			src={`/assets/illustrations/${illustration}.svg`}
		/>
	);
};
