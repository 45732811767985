import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";

export const Visual = styled.div<PropsWithTheme>`
	display: flex;
	position: relative;
	height: 540px;
	overflow: hidden;
	${({ theme: { palette } }) => css`
		background: ${palette.freeze[1000]};
		color: ${palette.freeze[0]};
	`};

	&::after {
		content: "";
		position: absolute;
		z-index: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.3);
	}
`;

export const VisualHeadline = styled.div<PropsWithTheme>`
	display: flex;
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	align-content: center;
	align-items: flex-end;
	justify-content: center;
	padding-bottom: var(--spacing-s);
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			align-items: center;
			padding-bottom: 0;
		}
	`};
`;

export const StyledMetaInfo = styled.div<PropsWithTheme>`
	display: flex;
	flex-wrap: wrap;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			justify-content: center;
		}
	`};
`;

export const StyledMetaInfoItem = styled.div<PropsWithTheme>`
	display: inline-flex;
	margin: var(--spacing-xxxs) var(--spacing-xxs) 0 0;
	padding: var(--spacing-xxxs);
	border-radius: 5px;
	line-height: 1;

	&:last-child {
		margin-right: 0;
	}

	${({ theme: { palette } }) => css`
		background: rgba(0, 0, 0, 0.3);
		color: ${palette.freeze[0]};
	`};
`;

export const StyledAuthorWrapper = styled.div<PropsWithTheme>`
	display: flex;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			justify-content: center;
		}
	`};
`;

export const StyledImageWrapper = styled.div`
	overflow: hidden;
	${({ theme: { tokens } }) => css`
		border-radius: ${tokens.borderRadius["3xl"]};
	`};
`;
