import React from "react";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import type { Document } from "@contentful/rich-text-types";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

import { Typography } from "@/components/typography/typography";

export const options = {
	renderMark: {
		[MARKS.BOLD]: text => <strong>{text}</strong>,
		[MARKS.ITALIC]: text => <em>{text}</em>,
	},
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node, children) => {
			return <Typography>{children}</Typography>;
		},
		[BLOCKS.UL_LIST]: (node, children) => {
			return <Typography component="ul">{children}</Typography>;
		},
		[BLOCKS.OL_LIST]: (node, children) => {
			return <Typography component="ol">{children}</Typography>;
		},
		[BLOCKS.LIST_ITEM]: node => {
			/**
			 * By default, list items will render children wrapped in our BLOCKS.PARAGRAPH element
			 * This leads to undesired extra margins.
			 * Here we use 'documentToReactComponents' to override this behaviour with custom elements
			 * https://github.com/contentful/rich-text/issues/126
			 */
			const transformedChildren = documentToReactComponents(node as Document, {
				renderMark: options.renderMark,
				renderNode: {
					[BLOCKS.PARAGRAPH]: (_node, children) => children,
					[BLOCKS.LIST_ITEM]: (_node, children) => children,
				},
			});
			return <li>{transformedChildren}</li>;
		},
	},
};
