import React, { useRef } from "react";

import type { Splide } from "@splidejs/react-splide";
import { SplideSlide, SplideTrack } from "@splidejs/react-splide";
import type { Options as SplideOptions } from "@splidejs/splide";
import { useTranslation } from "next-i18next";

import { BreakLines } from "@/components/break-lines";
import { Column, Grid, Row } from "@/components/grid";
import { BoxedQuote } from "@/components/quote";
import { Background } from "@/components/slot/components";
import { SlideshowButton } from "@/components/splide";
import { Typography } from "@/components/typography/typography";
import { Icon } from "@/design-system/atoms/icons";
import { BackgroundColor, TypographyVariant } from "@/theme";
import { BREAKPOINTS } from "@/theme/breakpoints";
import type { Testimonials as TestimonialsProps } from "@/types/contentful-api";

import "@splidejs/splide/dist/css/splide-core.min.css";
import { StyledControls, StyledSingleWrapper, StyledSplide, Wrapper } from "./styled";

const splideOptions: SplideOptions = {
	gap: "var(--spacing-xxxs)",
	keyboard: "focused",
	focus: "center",
	type: "loop",
	arrows: false,
	mediaQuery: "min",
	fixedWidth: "90%",
	breakpoints: {
		[BREAKPOINTS.m]: {
			focus: "center",
			fixedWidth: "auto",
			autoWidth: true,
		},
	},
};

export const BoxedTestimonials = ({ headline, itemsCollection: { items } }: TestimonialsProps) => {
	const ref = useRef<Splide>();
	const { t } = useTranslation("slideshow");

	return (
		<Background backgroundColor={BackgroundColor.grey}>
			{headline && (
				<Grid>
					<Row>
						<Column>
							<Typography
								bottom
								centered
								centeredOnMobile
								component="h2"
								variant={TypographyVariant.headlineSerifLG}
							>
								<BreakLines text={headline} />
							</Typography>
						</Column>
					</Row>
				</Grid>
			)}
			{items.length > 1 ? (
				<Wrapper>
					<StyledSplide
						hasTrack={false}
						options={{
							...splideOptions,
							i18n: {
								prev: t("slideshow:prev-property"),
								next: t("slideshow:next-property"),
								slidex: t("slideshow:go-to-property-x"),
							},
						}}
						ref={ref}
					>
						<SplideTrack>
							{items.map(item => (
								<SplideSlide key={item.sys.id}>
									<BoxedQuote {...item} />
								</SplideSlide>
							))}
						</SplideTrack>
						<StyledControls className="splide__arrows">
							<SlideshowButton
								className="splide__arrow splide__arrow--prev"
								onClick={() => {
									ref?.current.go("<");
								}}
							>
								<Icon icon="chevronLeft" />
							</SlideshowButton>
							<SlideshowButton
								className="splide__arrow splide__arrow--next"
								onClick={() => {
									ref?.current.go(">");
								}}
							>
								<Icon icon="chevronRight" />
							</SlideshowButton>
						</StyledControls>
					</StyledSplide>
				</Wrapper>
			) : (
				<Grid>
					<StyledSingleWrapper>
						{items?.[0] && <BoxedQuote {...items[0]} />}
					</StyledSingleWrapper>
				</Grid>
			)}
		</Background>
	);
};
