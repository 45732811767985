import React from "react";

import styled from "@emotion/styled";

import Image from "next/image";
import Link from "next/link";

import { Column, Grid, Hidden, Row } from "@/components/grid";
import { RelativeColumnRounded } from "@/components/grid/extensions";
import { Headline } from "@/components/headline";
import { ImageCard } from "@/components/image-card";
import { ImageCardLink } from "@/components/image-card/components";
import { Spacer } from "@/components/layout/components";
import { Md } from "@/components/markdown";
import { PersonEntryList } from "@/components/person-list";
import { Typography } from "@/components/typography/typography";
import dirs from "@/contentful/content-model/dirs";
import type { Locale } from "@/contentful/content-model/types";
import { Dirs, EntryListContentTypes } from "@/contentful/content-model/types";
import { StyledMetaInfoItem } from "@/templates/blog-post";
import { TypographyVariant } from "@/theme";
import type {
	BlogPost,
	EntryList as EntryListProps,
	Person,
	SubPage,
} from "@/types/contentful-api";
import { AssetFit, AssetFocus, AssetFormat } from "@/types/contentful-images";

export const EntryListSlot: React.VFC<
	EntryListProps & { locale: Locale; data?: SubPage[] | BlogPost[] | Person[] }
> = props => {
	const { headline, contentType, data, dir, locale } = props;

	switch (contentType) {
		case EntryListContentTypes.person: {
			return <PersonEntryList locale={locale} headline={headline} data={data as Person[]} />;
		}

		case EntryListContentTypes.subPage:
			return (
				<SubPageEntryList
					headline={headline}
					data={data as SubPage[]}
					dir={dir}
					locale={locale}
				/>
			);

		case EntryListContentTypes.blogPost:
			return <BlogEntryList headline={headline} data={data as BlogPost[]} locale={locale} />;

		default:
			return null;
	}
};

const SubPageEntryList: React.VFC<{
	headline?: string;
	data: SubPage[];
	dir: string;
	locale: Locale;
}> = ({ headline, data, dir, locale }) => (
	<Grid>
		<Row>
			<Column>
				<Headline headline={headline} />
				<Row>
					{data.map(item => {
						const href = `/${dirs[Dirs[dir]].dir[locale] as string}/${item.slug}`;
						return (
							<Column key={item.sys.id} l={8}>
								<Link passHref href={href} locale={locale}>
									<BlockAnchor>
										<Row>
											<RelativeColumnRounded s={0} m={3}>
												<Image
													src={`${item.featuredImage.url}?w=600&h=400&fm=${AssetFormat.webp}&f=${AssetFocus.center}&fit=${AssetFit.thumb}&q=75`}
													alt={item.featuredImage.description}
													layout="responsive"
													width="300"
													height="200"
													quality={75}
													sizes="30vw"
													loading="lazy"
												/>
											</RelativeColumnRounded>
											<Column s={4} m={5}>
												<Typography
													component="h3"
													variant={TypographyVariant.headlineSerifSM}
												>
													{item.headline}
												</Typography>
												<Md source={item.description} />
											</Column>
										</Row>
										<Row>
											<Column>
												<Spacer spacing="xs" />
											</Column>
										</Row>
									</BlockAnchor>
								</Link>
							</Column>
						);
					})}
				</Row>
			</Column>
		</Row>
	</Grid>
);

const BlogEntryList: React.VFC<{ headline?: string; data: BlogPost[]; locale: Locale }> = ({
	headline,
	data,
	locale,
}) => (
	<Grid>
		<Row>
			<Column>
				<Headline headline={headline} />
				<Row>
					<Column>
						<Row>
							{data.map(
								({
									sys: { id },
									slug,
									isNew,
									featuredImage,
									headline,
									categoriesCollection: { items: categories },
								}) => {
									return (
										<Column key={id} s={4} l={6}>
											<Link passHref href={`/blog/${slug}`} locale={locale}>
												<ImageCardLink data-test-id="blog-overview:link">
													<ImageCard
														large
														image={featuredImage}
														isNew={isNew}
													>
														{categories
															.filter(Boolean)
															.map(({ sys: { id }, description }) => (
																<StyledMetaInfoItem key={id}>
																	{description}
																</StyledMetaInfoItem>
															))}
														<Typography
															tight
															component="h2"
															variant={
																TypographyVariant.headlineSansXXS
															}
														>
															{headline}
														</Typography>
													</ImageCard>
												</ImageCardLink>
											</Link>
											<Hidden s l>
												<Spacer spacing="xs" />
											</Hidden>
											<Hidden m>
												<Spacer spacing="xxs" />
											</Hidden>
										</Column>
									);
								}
							)}
						</Row>
					</Column>
				</Row>
			</Column>
		</Row>
	</Grid>
);

const BlockAnchor = styled.a`
	display: block;
	text-decoration: none;
`;
