import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";

export const SlideshowButton = styled.button<
	PropsWithTheme<HTMLButtonElement> & { small?: boolean }
>`
	display: none;
	align-content: center;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 70px;
	margin: 0;
	padding: 0;
	border: none;
	border-radius: 50%;

	&[disabled] {
		opacity: 0.4;
	}

	${({ theme: { mq, palette, tokens }, small }) => css`
		background: ${palette.freeze[0]};
		box-shadow: ${tokens.elevation.bottom.LG};
		color: ${palette.freeze[1000]};
		width: ${small ? "56px" : "70px"};
		height: ${small ? "56px" : "70px"};

		@media ${mq.l} {
			cursor: pointer;
			visibility: visible;
			display: flex;
		}
	`};
`;

export const SlideshowButtonRound = styled.button<PropsWithTheme<HTMLButtonElement>>`
	display: none;
	align-content: center;
	align-items: center;
	justify-content: center;
	width: 56px;
	height: 56px;
	margin: 0;
	padding: 0;
	border: none;
	border-radius: 50%;

	&[disabled] {
		opacity: 0.4;
	}

	${({ theme: { mq, palette, tokens } }) => css`
		background: ${palette.freeze[0]};
		box-shadow: ${tokens.elevation.bottom.LG};
		color: ${palette.freeze[1000]};

		@media ${mq.l} {
			cursor: pointer;
			visibility: visible;
			display: flex;
		}
	`};
`;

export const Controls = styled.div`
	display: flex;
	visibility: hidden;
	position: absolute;
	z-index: 10;
	top: 50%;
	right: 0;
	left: 0;
	padding: 0 var(--spacing-xs);
	justify-content: space-between;
	transform: translateY(-50%);
`;
