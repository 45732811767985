import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Typography } from "@/components/typography/typography";
import { FONT_FACES } from "@/theme";
import type { PropsWithTheme } from "@/theme";

import QuotationMarkSVG from "../../../../public/assets/svg-icons/quotation-mark.svg";

export const StyledFigure = styled.div<PropsWithTheme>`
	height: 100%;
	margin: 0;
	padding: var(--spacing-xs);
	${({ theme: { mq, palette } }) => css`
		background-color: ${palette.freeze[50]};
		@media ${mq.m} {
			background-color: transparent;
			padding: 0;
		}
	`}
`;

export const StyledQuotationMark = styled(QuotationMarkSVG)<PropsWithTheme>`
	width: 32px;
	margin-top: var(--spacing-xxs);
	margin-bottom: var(--spacing-xs);
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			margin-top: 0;
			margin-bottom: var(--spacing-xl);
			width: 72px;
		}
	`}
`;

export const StyledBlockQuote = styled.p<PropsWithTheme & { smallFontSize: boolean }>`
	font-family: ${FONT_FACES.sans};
	font-weight: 300;
	line-height: 28px;
	margin: 0;
	${({ theme: { mq }, smallFontSize }) => css`
		@media ${mq.m} {
			font-family: ${FONT_FACES.serif};
			font-size: ${smallFontSize ? "24px" : "36px"};
			font-weight: 400;
			line-height: ${smallFontSize ? "36px" : "50px"};
		}
	`}
`;

export const StyledStars = styled.div<PropsWithTheme>`
	display: flex;
	gap: 5px;
	margin-top: var(--spacing-xs);
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			margin-top: var(--spacing-s);
		}
	`}
`;

export const StyledImageWrapper = styled.div<PropsWithTheme>`
	position: relative;
	width: 32px;
	height: 32px;
	margin: var(--spacing-xxs) 0 0;

	img {
		border-radius: 50%;
	}

	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			width: 60px;
			height: 60px;
			margin-top: var(--spacing-xs);
		}
	`}
`;

export const StyledFigCaptionTypography = styled(Typography)<PropsWithTheme>`
	margin: var(--spacing-xxxs) 0 0;
	font-size: 12px;
	font-weight: 300;
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			font-size: initial;
			font-weight: 500;
		}
	`}
`;
