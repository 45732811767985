import { useTranslation } from "next-i18next";

import { useRouter } from "next/router";

import { useFormattedPrice } from "@/hooks/number-format";

export const usePropertyPrice = (
	price: number,
	salesStatus: string | boolean,
	hidePrice: boolean,
	isProject: boolean
) => {
	const { locale } = useRouter();
	const { t } = useTranslation(["common", "property"]);
	const formattedPrice = useFormattedPrice({ locale, value: price });
	const formattedPriceWithFrom = isProject
		? `${t("common:from")} ${formattedPrice}`
		: formattedPrice;
	return salesStatus === "sold"
		? t("property:sold")
		: hidePrice
		? t("property:price-on-request")
		: formattedPriceWithFrom;
};
