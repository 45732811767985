import React from "react";

import Image from "next/image";

import { Column, Grid, Row } from "@/components/grid";
import { Spacer } from "@/components/layout/components";
import { Typography } from "@/components/typography/typography";
import { FONT_WEIGHT, TypographyVariant } from "@/theme";
import type { Picture as PictureSlotProps } from "@/types/contentful-api";

export const PictureSlot: React.FC<PictureSlotProps> = props => {
	const cropParameters = props.focus && props.fit ? `&f=${props.focus}&fit=${props.fit}` : "";
	return (
		<Grid>
			<Row>
				<Column>
					<Image
						src={`${props.file.url}?fm=webp${cropParameters}`}
						alt={props.alt ?? ""}
						layout="responsive"
						width={props.file.width}
						height={props.file.height}
						quality={75}
						sizes="(max-width: 1199px) 100vw, 60vw"
						loading="lazy"
					/>
					{props.source && (
						<>
							<Spacer spacing="xxxs" />
							<Typography
								variant={TypographyVariant.bodySM}
								weight={FONT_WEIGHT.light}
							>
								Quelle: {props.source}
							</Typography>
						</>
					)}
				</Column>
			</Row>
		</Grid>
	);
};
