import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import { StyledTextArea } from "@/components/contentful/components/newsletter/components";
import { Column, Grid, Row } from "@/components/grid";
import { ScrollableRow, SnapColumnMobile } from "@/components/grid/extensions";
import { Spacer } from "@/components/layout/components";
import { Md } from "@/components/markdown";
import { StyledLink } from "@/components/slot/components";
import { Typography } from "@/components/typography/typography";
import type { Locale } from "@/contentful/content-model/types";
import { TypographyVariant } from "@/theme";
import type { PropsWithTheme } from "@/theme";
import type { MultiLink as MultiLinkSlotProps, SingleLinkEntryLink } from "@/types/contentful-api";
import { imageLoaderFaceFill } from "@/utils/image";
import { getLinkFromEntryLink } from "@/utils/urls";

const NextImageWrapper = styled.div<PropsWithTheme>`
	position: relative;
	width: 100%;
	aspect-ratio: 1.4;
	overflow: hidden;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			height: 192px;
		}
	`}
`;

const StyledColumnWithBackground = styled(SnapColumnMobile)<PropsWithTheme>`
	overflow: hidden;
	width: 85%;
	${({ theme: { palette, tokens } }) => css`
		background-color: ${palette.freeze[50]};
		border-radius: ${tokens.borderRadius["3xl"]};
	`};
`;

const StyledLinksWrapper = styled.div<PropsWithTheme>`
	padding: var(--spacing-xs) var(--spacing-xs) var(--spacing-xxs);
`;

export interface CategoryItemProps {
	entryLink: SingleLinkEntryLink;
	linkText: string;
	linkDescription: string;
}

export const CategoryItem: React.FC<CategoryItemProps> = props => {
	const { entryLink, linkText, linkDescription } = props;
	const { locale } = useRouter();
	const linkConfig = getLinkFromEntryLink(entryLink, locale as Locale);

	return (
		<>
			{linkDescription && (
				<>
					<Typography tight>{linkDescription}</Typography>
					<Spacer spacing="xxxs" />
				</>
			)}
			{linkText && linkConfig.href && (
				<Link
					passHref
					href={linkConfig.href}
					as={linkConfig.as}
					locale={locale}
					prefetch={false}
				>
					<StyledLink>
						<Typography bottom>{linkText}</Typography>
					</StyledLink>
				</Link>
			)}
		</>
	);
};

export const MultiLinkSlot: React.FC<MultiLinkSlotProps> = props => {
	const {
		headline,
		description,
		slotsCollection: { items },
	} = props;

	return (
		<Grid>
			<Row>
				<Column flex l={7}>
					<StyledTextArea>
						<Typography
							tight
							component="h2"
							variant={TypographyVariant.headlineSerifLG}
						>
							{headline}
						</Typography>
						{description && <Md source={description} />}
						<Spacer spacing="s" />
					</StyledTextArea>
				</Column>
			</Row>
			<ScrollableRow>
				{items.map(item => {
					const { featuredImage } = item;
					return (
						<StyledColumnWithBackground key={item.sys.id} m={2} l={3}>
							{featuredImage && (
								<NextImageWrapper>
									<Image
										loader={imageLoaderFaceFill}
										src={featuredImage.url}
										alt={featuredImage.description ?? ""}
										layout="fill"
										objectFit="cover"
										quality={50}
										sizes="(max-width: 767px) 100vw, 20vw"
										loading="lazy"
									/>
								</NextImageWrapper>
							)}
							<StyledLinksWrapper>
								{item.entryLink1 && (
									<CategoryItem
										entryLink={item.entryLink1}
										linkText={item.linkText1}
										linkDescription={item.linkDescription1}
									/>
								)}
								{item.entryLink2 && (
									<CategoryItem
										entryLink={item.entryLink2}
										linkText={item.linkText2}
										linkDescription={item.linkDescription2}
									/>
								)}
								{item.entryLink3 && (
									<CategoryItem
										entryLink={item.entryLink3}
										linkText={item.linkText3}
										linkDescription={item.linkDescription3}
									/>
								)}
								{item.entryLink4 && (
									<CategoryItem
										entryLink={item.entryLink4}
										linkText={item.linkText4}
										linkDescription={item.linkDescription4}
									/>
								)}
								{item.entryLink5 && (
									<CategoryItem
										entryLink={item.entryLink5}
										linkText={item.linkText5}
										linkDescription={item.linkDescription5}
									/>
								)}
								{item.entryLink6 && (
									<CategoryItem
										entryLink={item.entryLink6}
										linkText={item.linkText6}
										linkDescription={item.linkDescription6}
									/>
								)}
							</StyledLinksWrapper>
						</StyledColumnWithBackground>
					);
				})}
			</ScrollableRow>
		</Grid>
	);
};
