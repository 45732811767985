import React from "react";

import styled from "@emotion/styled";

import Image from "next/image";

import { Typography } from "@/components/typography/typography";
import type { PropsWithTheme } from "@/theme";
import { FontWeight } from "@/theme";
import { AssetFit, AssetFocus, AssetFormat } from "@/types/contentful-images";
import type { ImageProps } from "@/types/image";

const imageDiameter = 32;

export const StyledAuthor = styled.div<PropsWithTheme>`
	display: inline-flex;
	align-items: center;
`;

export const StyledAuthorImage = styled.span`
	position: relative;
	width: ${imageDiameter}px;
	height: ${imageDiameter}px;
	margin-right: var(--spacing-xxxs);
	overflow: hidden;
	border-radius: 50%;
`;

export const Author: React.VFC<{ featuredImage: ImageProps; name: string }> = ({
	featuredImage: { description, url },
	name,
}) => {
	const diameter = imageDiameter * 2;
	return (
		<StyledAuthor>
			<StyledAuthorImage>
				<Image
					unoptimized
					loading="lazy"
					src={`${url}?q=70&w=${diameter}&h=${diameter}&fm=${AssetFormat.webp}&f=${AssetFocus.face}&fit=${AssetFit.thumb}`}
					alt={description}
					layout="fill"
					objectFit="cover"
				/>
			</StyledAuthorImage>
			<Typography component="h5" weight={FontWeight.medium}>
				{name}
			</Typography>
		</StyledAuthor>
	);
};
