import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Splide } from "@splidejs/react-splide";

import { Controls } from "@/components/splide";
import type { PropsWithTheme } from "@/theme";

export const StyledControls = styled(Controls)`
	display: none;
	padding: 0;
	width: 100%;
`;

export const Wrapper = styled.div`
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			margin: 0 auto;
			max-width: 1128px;
			overflow-x: hidden;
			width: 100%;
		}
	`}
`;

export const StyledSplide = styled(Splide)<PropsWithTheme>`
	overflow: hidden;
	padding-bottom: 60px;

	.splide__pagination {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		margin-top: 30px;
		padding-left: 0;

		button {
			cursor: pointer;
		}
	}

	.splide__track {
		overflow: visible;
	}

	&:hover {
		div${StyledControls} {
			display: flex !important;
		}
	}

	${({ theme: { mq, palette } }) => css`
		.splide__pagination__page {
			display: inline-block;
			position: relative;
			width: 8px;
			height: 8px;
			margin: 3px;
			padding: 0;
			border: 0;
			border-radius: 50%;
			background-color: ${palette.freeze[300]};

			&.is-active {
				transform: scale(1.4);
				background: ${palette.freeze[1000]};
			}
		}

		@media ${mq.m} {
			.splide__pagination {
				/* Otherwise it hides at 2 slides but we want it */
				display: flex !important;
				margin-top: 28px;
			}
		}
	`}
`;

export const StyledSingleWrapper = styled.div`
	display: flex;
	justify-content: center;
`;
