import React from "react";

import { ActionButtonLink } from "@/components/action-button-link";
import { PropertyListCollage } from "@/components/contentful/components/property-list/property-list-collage";
import { PropertyListSlider } from "@/components/contentful/components/property-list/property-list-slider";
import { Column, Grid, Row } from "@/components/grid";
import { Spacer } from "@/components/layout/components";
import { Typography } from "@/components/typography/typography";
import { DisplayType } from "@/contentful/content-model/types";
import { StyledLinkWrapper } from "@/design-system/atoms/button";
import { TypographyVariant } from "@/theme";
import type { PropertyList as PropertyListSlotProps } from "@/types/contentful-api";

// The collage property list is styled to handle max 5 property slots
const LIMIT_COLLAGE_PROPERTIES = 5;

export const PropertyListSlot: React.FC<PropertyListSlotProps> = ({
	headline,
	navigationItem,
	displayType,
	itemsCollection,
}) => {
	return (
		<Grid>
			<Row>
				<Column>
					{headline && (
						<>
							<Typography
								bottom
								centered
								component="h2"
								variant={TypographyVariant.headlineSerifLG}
							>
								{headline}
							</Typography>
							<Spacer spacing="xxs" />
						</>
					)}
					{displayType === DisplayType.slider && (
						<PropertyListSlider properties={itemsCollection.items} />
					)}
					{displayType === DisplayType.collage && (
						<PropertyListCollage
							properties={itemsCollection.items.slice(0, LIMIT_COLLAGE_PROPERTIES)}
						/>
					)}
					{navigationItem && (
						<>
							<Spacer spacing="m" />
							<StyledLinkWrapper>
								<ActionButtonLink navigationItem={navigationItem} />
							</StyledLinkWrapper>
						</>
					)}
				</Column>
			</Row>
		</Grid>
	);
};
