import React from "react";

import ImageText from "@/components/contentful/components/image-text";
import { Column, Grid, Row } from "@/components/grid";
import type { ImageText as ImageTextSlotProps } from "@/types/contentful-api";

export const ImageTextSlot: React.FC<ImageTextSlotProps> = ({
	headline,
	displayType,
	sys: { id },
	description,
	imagesCollection,
	navigationItem,
	flip,
}) => {
	return (
		<Grid>
			<Row>
				<Column>
					<ImageText
						imagesCollection={imagesCollection}
						description={description}
						headline={headline}
						flip={flip}
						id={id}
						navigationItem={navigationItem}
						componentDisplayType={displayType}
					/>
				</Column>
			</Row>
		</Grid>
	);
};
