import React from "react";

import Image from "next/image";

import { Typography } from "@/components/typography/typography";
import { FontWeight, TypographyVariant } from "@/theme";
import type { Quote as QuoteProps } from "@/types/contentful-api";
import { AssetFit, AssetFocus, AssetFormat } from "@/types/contentful-images";

import {
	StyledAdditionalInfo,
	StyledCitation,
	StyledFigCaption,
	StyledFigure,
	StyledImageWrapper,
	StyledQuote,
} from "./styled";

export const BoxedQuote = ({ additionalInfo, quote, name, featuredImage }: QuoteProps) => {
	return (
		<StyledFigure>
			{featuredImage && (
				<StyledImageWrapper>
					<Image
						loading="eager"
						src={`${featuredImage.url}?q=75&w=271&fm=${AssetFormat.webp}&f=${AssetFocus.faces}&fit=${AssetFit.fill}`}
						alt={featuredImage.description || name}
						layout="fill"
						objectFit="cover"
					/>
				</StyledImageWrapper>
			)}
			<StyledFigCaption>
				<StyledQuote>
					<Typography variant={TypographyVariant.bodyLG} weight={FontWeight.light} tight>
						{quote}
					</Typography>
				</StyledQuote>
				<StyledCitation>
					{name && (
						<Typography
							tight
							variant={TypographyVariant.bodyLG}
							weight={FontWeight.medium}
						>
							{name}
						</Typography>
					)}
					{additionalInfo && (
						<StyledAdditionalInfo
							tight
							light
							variant={TypographyVariant.bodyLG}
							weight={FontWeight.light}
						>
							{additionalInfo}
						</StyledAdditionalInfo>
					)}
				</StyledCitation>
			</StyledFigCaption>
		</StyledFigure>
	);
};
