import React from "react";

import Image from "next/image";

import {
	StyledTextArea,
	VisualContent,
	VisualImg,
	WidgetBox,
} from "@/components/contentful/components/newsletter/components";
import { Column, Row } from "@/components/grid";
import { NewsletterSignup } from "@/components/newsletter-signup";
import { Typography } from "@/components/typography/typography";
import { TypographyVariant } from "@/theme";
import type {
	NewsletterSubscription as NewsletterSubscriptionProps,
	TextCollection,
} from "@/types/contentful-api";
import { AssetFit, AssetFocus, AssetFormat } from "@/types/contentful-images";

export const Newsletter: React.FC<
	{ textCollection: TextCollection } & NewsletterSubscriptionProps
> = ({ textCollection, ...props }) => {
	const { subtitle, headline, featuredImage } = props;

	return (
		<Row>
			<Column flex l={6}>
				<VisualContent>
					<Row>
						<Column flex l={5}>
							<StyledTextArea>
								<Typography
									tight
									component="span"
									variant={TypographyVariant.headlineSerifLG}
								>
									{headline}
								</Typography>
								<Typography>{subtitle}</Typography>
							</StyledTextArea>
						</Column>
					</Row>
					<Row>
						<Column flex l={7}>
							<WidgetBox>
								<NewsletterSignup textCollection={textCollection} />
							</WidgetBox>
						</Column>
					</Row>
				</VisualContent>
			</Column>
			<Column s={0} l={6}>
				<VisualImg>
					<Image
						unoptimized
						loading="lazy"
						src={`${featuredImage.url}?q=50&h=740&fm=${AssetFormat.webp}&f=${AssetFocus.center}&fit=${AssetFit.fill}`}
						alt={featuredImage.description ?? ""}
						layout="fill"
						objectFit="cover"
					/>
				</VisualImg>
			</Column>
		</Row>
	);
};
