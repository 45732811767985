export enum JobLevel {
	management = "management",
	departmentHead = "departmentHead",
	operations = "operations",
	intern = "intern",
	realEstateVP = "realEstateVP",
	realEstateTeamLead = "realEstateTeamLead",
	realEstateTeamManager = "realEstateTeamManager",
	realEstateAgent = "realEstateAgent",
}

export enum JobDepartment {
	realEstate = "realEstate",
	headquarter = "headquarter",
}

export interface SocialMediaLinks {
	facebookUrl?: string;
	instagramUrl?: string;
	linkedInUrl?: string;
	xingUrl?: string;
	twitterUrl?: string;
}

export interface VCardProps extends SocialMediaLinks {
	email?: string;
	name: string;
	homepageUrl: string;
	mobileNumber?: string;
	telephoneNumber?: string;
	jobPosition?: string;
}
