import React from "react";

import dynamic from "next/dynamic";

import { Column, Grid, Row } from "@/components/grid";
import type { Iframe as IframeSlotProps } from "@/types/contentful-api";

const Iframe = dynamic(() => import("./generic").then(mod => mod.Iframe));
const SpotifyIframe = dynamic(() => import("./spotify").then(mod => mod.SpotifyIframe));

export const IframeSlot = (props: IframeSlotProps) => {
	const isSpotifyLink = props.iframeUrl.includes("https://open.spotify.com/");
	return (
		<Grid>
			<Row>
				<Column>
					{isSpotifyLink ? <SpotifyIframe {...props} /> : <Iframe {...props} />}
				</Column>
			</Row>
		</Grid>
	);
};
