import React from "react";

import { People } from "@/components/contentful/components/people";
import { Column, Grid, Row } from "@/components/grid";
import { Headline } from "@/components/headline";
import type { Locale } from "@/contentful/content-model/types";
import type { Person } from "@/types/contentful-api";

export const PersonEntryList: React.VFC<{
	locale: Locale;
	headline?: string;
	data: Person[];
}> = ({ locale, headline, data }) => (
	<Grid>
		<Row>
			<Column>
				<Headline headline={headline} />
				<People locale={locale} team={data} />
			</Column>
		</Row>
	</Grid>
);
