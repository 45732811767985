import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Splide } from "@splidejs/react-splide";

import { SlideshowButton } from "@/components/splide";
import type { PropsWithTheme } from "@/theme";

export const StyledSplide = styled(Splide)<PropsWithTheme>`
	.splide__pagination {
		margin-top: var(--spacing-xs);
		padding-left: 0;
		button {
			cursor: pointer;
		}
	}
	${({ theme: { mq, palette } }) => css`
		.splide__pagination__page.is-active {
			transform: scale(1.4);
			background: ${palette.freeze[1000]};
		}
		.splide__pagination__page {
			display: inline-block;
			position: relative;
			width: 8px;
			height: 8px;
			margin: 3px;
			padding: 0;
			border: 0;
			border-radius: 50%;
			background-color: ${palette.freeze[300]};
		}

		@media ${mq.m} {
			.splide__pagination {
				margin-top: calc(var(--spacing-xxs) * -1);
			}
		}
	`}
`;

export const StyledSlideshowButton = styled(SlideshowButton)<PropsWithTheme>`
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			// 72px is height of StyledQuotationMark svg + its bottom margin
			margin-top: calc(72px + var(--spacing-xl));
		}
	`}
`;
