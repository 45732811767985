import React from "react";

import { useTranslation } from "next-i18next";

import Image from "next/image";

import { Icon } from "@/design-system/atoms/icons";
import type { Quote as QuoteProps } from "@/types/contentful-api";
import { AssetFit, AssetFocus, AssetFormat } from "@/types/contentful-images";

import {
	StyledBlockQuote,
	StyledFigCaptionTypography,
	StyledFigure,
	StyledImageWrapper,
	StyledQuotationMark,
	StyledStars,
} from "./styled";

export const StandaloneQuote: React.VFC<QuoteProps> = ({
	sys,
	quote,
	name,
	rating,
	featuredImage,
}) => {
	const { t } = useTranslation(["common"]);
	return (
		<StyledFigure>
			<StyledQuotationMark aria-hidden="true" focusable="false" />
			<StyledBlockQuote smallFontSize={quote.length > 150}>{quote}</StyledBlockQuote>
			{Number.isInteger(rating) && (
				<StyledStars aria-label={`${rating} ${t("common:stars")}`}>
					{[...new Array(5)].map((_, index) => (
						<Icon
							// eslint-disable-next-line  react/no-array-index-key
							key={`rating${index}${sys.id}`}
							icon="starFilled"
							color={index < rating ? "#ffd600" : "#e5e5e5"}
						/>
					))}
				</StyledStars>
			)}
			{featuredImage && (
				<StyledImageWrapper>
					<Image
						unoptimized
						loading="lazy"
						src={`${featuredImage.url}?q=75&w=80&h=80&fm=${AssetFormat.webp}&f=${AssetFocus.face}&fit=${AssetFit.thumb}`}
						alt={featuredImage.description || ""}
						layout="fill"
						objectFit="cover"
					/>
				</StyledImageWrapper>
			)}
			{name && (
				<figcaption>
					<StyledFigCaptionTypography>-- {name}</StyledFigCaptionTypography>
				</figcaption>
			)}
		</StyledFigure>
	);
};
