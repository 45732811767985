import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { RowProps } from "@/components/grid";
import { Column, GUTTER, Row } from "@/components/grid";
import type { PropsWithTheme } from "@/theme";

export const ScrollableRow = styled(Row)<PropsWithTheme & RowProps>`
	flex-wrap: nowrap;
	padding-bottom: 20px;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	scroll-snap-type: x mandatory;
	scroll-padding: 0 calc(var(${GUTTER}) * 1px);
	clip-path: polygon(
		calc(var(${GUTTER}) * 1px) 0,
		calc(var(${GUTTER}) * 1px) calc(100% - 20px),
		calc(100% - var(${GUTTER}) * 1px) calc(100% - 20px),
		calc(100% - var(${GUTTER}) * 1px) 0
	);
`;

export const SnapColumn = styled(Column)`
	scroll-snap-align: start;
`;

// @todo 38px offset is hardcoded to take the caption into account
export const Pagers = styled.div<PropsWithTheme>`
	display: flex;
	visibility: hidden;
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	justify-content: space-between;
	margin-top: -38px;
	transform: translateY(-50%);
`;

export const AbsoluteRow = styled(Row)`
	visibility: hidden;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 20px;
	left: 0;
`;

export const SlideshowWrapper = styled.div`
	position: relative;
`;

export const SlideSizer = styled.div<{ aspectRatio: number }>`
	position: relative;
	${({ aspectRatio }) => css`
		padding-bottom: ${aspectRatio * 100}%;
	`};
`;

export const SlideInner = styled.div<{ withLightbox?: boolean }>`
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	${({ theme: { tokens }, withLightbox }) => css`
		border-radius: ${tokens.borderRadius["3xl"]};
		cursor: ${withLightbox ? "pointer" : "default"};
	`};
`;

// @todo line-height of the caption (28px + spacing-xs) is the bottom offset
export const NumbersWrapper = styled.div`
	visibility: visible;
	position: absolute;
	right: var(--spacing-xs);
	bottom: calc(var(--spacing-xs) + 28px);
`;

export const Caption = styled.div`
	margin-top: var(--spacing-xxs);
`;
