import React from "react";

import Image from "next/image";
import Link from "next/link";

import { StyledBox, StyledImageWrapper, StyledLink, StyledRow } from "@/components/boxes/styled";
import type { BoxesComponentProps } from "@/components/boxes/types";
import { Alignment, Column } from "@/components/grid";
import type { Images } from "@/types/contentful-api";
import { AssetFormat } from "@/types/contentful-images";

export const LogosBoxes: React.VFC<BoxesComponentProps<Images[]>> = ({ items }) => {
	// One Images content-type holds the logo Asset's that we use for this component
	const logos = items?.[0].imagesCollection.items ?? [];

	return (
		<StyledRow justify={Alignment.center}>
			{logos.map(logo => {
				let description = "";
				let externalLink = "";
				try {
					const data = JSON.parse(logo.description);

					if (data) {
						description = data.description;
						externalLink = data.externalLink;
					}
				} catch {
					// intentionally left blank
				}

				const image = (
					<StyledBox>
						<StyledImageWrapper>
							<Image
								src={`${logo.url}?q=90&w=300&fm=${AssetFormat.webp}`}
								alt={description}
								layout="fill"
								objectFit="contain"
								quality={75}
							/>
						</StyledImageWrapper>
					</StyledBox>
				);

				return (
					<Column key={logo.sys.id} flex s={2}>
						{externalLink ? (
							<Link as={externalLink} href={externalLink} passHref>
								<StyledLink target="_blank" rel="noopener noreferrer">
									{image}
								</StyledLink>
							</Link>
						) : (
							image
						)}
					</Column>
				);
			})}
		</StyledRow>
	);
};
