export const getComputedAspectRatio = (aspectRatio: string = "4:3") =>
	1 /
	aspectRatio.split(":").reduce((previousValue, currentValue) => {
		const valueAsNumber = Number.parseInt(currentValue, 10);
		if (previousValue > 0) {
			return previousValue / valueAsNumber;
		}

		return valueAsNumber;
	}, 0);
