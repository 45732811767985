import React from "react";

// ToDo: Dynamic imports
import { SlotWrapper } from "@/components/slot/components";
import type { Locale } from "@/contentful/content-model/types";
import type { StrictUnion } from "@/types/common";
import type {
	Boxes as BoxesProps,
	BlogPostSlotsItem,
	ContactForm as ContactFormProps,
	DistrictSlotsItem,
	EntryList as EntryListSlotProps,
	JobApplicationForm as JobApplicationFormSlotProps,
	JobListings as JobListingsSlotProps,
	Iframe as IframeSlotProps,
	ImageSlideshow as ImageSlideshowProps,
	ImageText as ImageTextProps,
	List as FaqSlotProps,
	MapLocationLinks as MapLocationLinksSlotProps,
	Markdown as MarkdownSlotProps,
	MultiLink as MultiLinkProps,
	NewsletterSubscription as NewsletterSubscriptionProps,
	PageSlotsItem,
	Picture as PictureProps,
	PictureText as PictureTextProps,
	PropertyList as PropertyListProps,
	PropertySlotsItem,
	SingleLink as SingleLinkProps,
	Slideshow as SlideshowProps,
	SubPageSlotsItem,
	Table as TableSlotProps,
	Testimonials as TestimonialsProps,
	Video as VideoSlotProps,
	SubPage,
	BlogPost,
	Person,
	TextCollection,
	PersonList as PersonListProps,
} from "@/types/contentful-api";

import { BoxesSlot } from "./slots/boxes";
import { ContactFormSlot } from "./slots/contact-form";
import { EntryListSlot } from "./slots/entry-list";
import { FaqSlot } from "./slots/faq";
import { ImageSlideshowSlot } from "./slots/image-slideshow";
import { ImageTextSlot } from "./slots/image-text";
import { IframeSlot } from "./slots/inline-frame";
import { JobApplicationFormSlot } from "./slots/job-application";
import { JobListingsSlot } from "./slots/job-listings";
import { MapLocationLinksSlot } from "./slots/map-location-links";
import { MarkdownSlot } from "./slots/markdown";
import { MultiLinkSlot } from "./slots/multi-link";
import { NewsletterSlot } from "./slots/newsletter";
import { PersonListSlot } from "./slots/person-list";
import { PictureSlot } from "./slots/picture";
import { PictureTextSlot } from "./slots/picture-text";
import { PropertyListSlot } from "./slots/property-list";
import { SingleLinkSlot } from "./slots/single-link";
import { SlideshowSlot } from "./slots/slider";
import { TableSlot } from "./slots/table";
import { BoxedTestimonials } from "./slots/testimonials/boxed";
import { StandaloneTestimonials } from "./slots/testimonials/standalone";
import { VideoSlot } from "./slots/video";

type SlotProps =
	| StrictUnion<PageSlotsItem>
	| StrictUnion<SubPageSlotsItem>
	| StrictUnion<PropertySlotsItem>
	| StrictUnion<DistrictSlotsItem>
	| StrictUnion<Partial<BlogPostSlotsItem>>;

export const Slot: React.FC<
	SlotProps & {
		locale: Locale;
		data?: SubPage[] | BlogPost[] | Person[];
		textCollection?: TextCollection;
	}
> = ({ locale, textCollection, ...props }) => {
	switch (props?.__typename) {
		case "Markdown":
			return (
				<SlotWrapper>
					<MarkdownSlot {...(props as MarkdownSlotProps)} />
				</SlotWrapper>
			);
		case "NewsletterSubscription":
			return (
				<SlotWrapper>
					<NewsletterSlot
						{...(props as NewsletterSubscriptionProps)}
						textCollection={textCollection}
					/>
				</SlotWrapper>
			);
		case "JobApplicationForm":
			return (
				<SlotWrapper>
					<JobApplicationFormSlot
						{...(props as JobApplicationFormSlotProps)}
						textCollection={textCollection}
					/>
				</SlotWrapper>
			);
		case "JobListings":
			return (
				<SlotWrapper>
					<JobListingsSlot {...(props as JobListingsSlotProps)} />
				</SlotWrapper>
			);
		case "Iframe":
			return (
				<SlotWrapper>
					<IframeSlot {...(props as IframeSlotProps)} />
				</SlotWrapper>
			);
		case "Table":
			return (
				<SlotWrapper>
					<TableSlot {...(props as TableSlotProps)} />
				</SlotWrapper>
			);
		case "Video":
			return (
				<SlotWrapper>
					<VideoSlot {...(props as VideoSlotProps)} />
				</SlotWrapper>
			);
		case "EntryList":
			return (
				<SlotWrapper>
					<EntryListSlot
						locale={locale}
						{...(props as EntryListSlotProps & {
							data?: SubPage[] | BlogPost[] | Person[];
						})}
					/>
				</SlotWrapper>
			);
		case "SingleLink":
			return (
				<SlotWrapper>
					<SingleLinkSlot {...(props as SingleLinkProps)} />
				</SlotWrapper>
			);
		case "MultiLink":
			return (
				<SlotWrapper>
					<MultiLinkSlot {...(props as MultiLinkProps)} />
				</SlotWrapper>
			);
		case "Slideshow":
			return <SlideshowSlot {...(props as SlideshowProps)} />;
		case "ImageSlideshow":
			return <ImageSlideshowSlot {...(props as ImageSlideshowProps)} />;
		case "Picture":
			return (
				<SlotWrapper>
					<PictureSlot {...(props as PictureProps)} />
				</SlotWrapper>
			);
		case "PictureText":
			return <PictureTextSlot {...(props as PictureTextProps)} />;
		case "List":
			return <FaqSlot {...(props as FaqSlotProps)} />;
		case "ContactForm":
			return (
				<SlotWrapper>
					<ContactFormSlot
						{...(props as ContactFormProps)}
						textCollection={textCollection}
					/>
				</SlotWrapper>
			);
		case "ImageText":
			return (
				<SlotWrapper>
					<ImageTextSlot {...(props as ImageTextProps)} />
				</SlotWrapper>
			);
		case "PropertyList":
			return (
				<SlotWrapper>
					<PropertyListSlot {...(props as PropertyListProps)} />
				</SlotWrapper>
			);
		case "Testimonials":
			return props.displayType === "Box" ? (
				<BoxedTestimonials {...(props as TestimonialsProps)} />
			) : (
				<SlotWrapper>
					<StandaloneTestimonials {...(props as TestimonialsProps)} />
				</SlotWrapper>
			);
		case "Boxes":
			return <BoxesSlot {...(props as BoxesProps)} />;
		case "PersonList":
			return (
				<SlotWrapper>
					<PersonListSlot locale={locale} {...(props as PersonListProps)} />
				</SlotWrapper>
			);
		case "MapLocationLinks":
			return (
				<SlotWrapper>
					<MapLocationLinksSlot
						locale={locale}
						{...(props as MapLocationLinksSlotProps)}
					/>
				</SlotWrapper>
			);
		default:
			return null;
	}
};

export const SlotsWithCustomImageText: React.FC<
	SlotProps & {
		flipOverride: boolean;
		locale: Locale;
		data?: SubPage[] | BlogPost[] | Person[];
		textCollection?: TextCollection;
	}
> = ({ flipOverride, ...slot }) => {
	return (
		<React.Fragment key={slot.sys.id}>
			{slot?.__typename === "ImageText" ? (
				<SlotWrapper>
					<ImageTextSlot {...(slot as ImageTextProps)} flip={flipOverride} />
				</SlotWrapper>
			) : (
				<Slot {...slot} />
			)}
		</React.Fragment>
	);
};
