import React from "react";

import Image from "next/image";

import { ActionButtonLink } from "@/components/action-button-link";
import { BreakLines } from "@/components/break-lines";
import type { ImageTextComponentProps } from "@/components/contentful/types";
import { Column, Row } from "@/components/grid";
import { RelativeColumn, RelativeRow } from "@/components/grid/extensions";
import { Md } from "@/components/markdown";
import { Typography } from "@/components/typography/typography";
import { TypographyVariant } from "@/theme";
import { AssetFit, AssetFocus, AssetFormat } from "@/types/contentful-images";

import { AbsoluteRow, Hidden, StyledImageWrapper } from "../styled";

export const VisualImageText: React.FC<ImageTextComponentProps> = ({
	description,
	headline,
	navigationItem,
	dark,
	imagesCollection: {
		items: [image],
	},
}) => {
	return (
		<>
			<Row>
				<Column l={7}>
					<Typography tight component="h2" variant={TypographyVariant.headlineSerifLG}>
						<BreakLines text={headline} />
					</Typography>
					<Hidden m s>
						<Md source={description} />
					</Hidden>
				</Column>
			</Row>
			<RelativeRow>
				<RelativeColumn>
					<Row noWrap>
						<Column s={0} l={3} />
						<Column>
							{image && (
								<StyledImageWrapper>
									<Image
										unoptimized
										loading="lazy"
										src={`${image.url}?q=75&h=740&fm=${AssetFormat.webp}&f=${AssetFocus.faces}&fit=${AssetFit.fill}`}
										alt={image.description ?? ""}
										layout="responsive"
										width={image.width}
										height={image.height}
										objectPosition="top center"
									/>
								</StyledImageWrapper>
							)}
						</Column>
					</Row>
					<Hidden l>
						<AbsoluteRow>
							<Column l={5}>
								<Md source={description} />
								<ActionButtonLink navigationItem={navigationItem} dark={dark} />
							</Column>
						</AbsoluteRow>
					</Hidden>
					<Hidden m s>
						<ActionButtonLink navigationItem={navigationItem} dark={dark} />
					</Hidden>
				</RelativeColumn>
			</RelativeRow>
		</>
	);
};
