import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "next-i18next";

import { TextButton } from "@/components/buttons";
import { ButtonVariants, TextButtonVariants } from "@/components/buttons/types";
import { Column, Grid, Row } from "@/components/grid";
import { Select } from "@/components/select";
import { SlotHeader } from "@/components/slot/components";
import type { JobsFilters, PartialJob } from "@/context/jobs/";
import { useJobsContext } from "@/context/jobs/";
import type { JobListings } from "@/types/contentful-api";

import { JobList, NoJobsFound } from "./components";
import { StyledFilters, StyledErrorText, StyledLoadMoreButton } from "./styled";

const JOBS_TO_SHOW_INCREMENT = 10;

export const JobListingsSlot = ({
	headline,
	description,
	jobFilters: initialFilters,
}: JobListings) => {
	const { t } = useTranslation(["common", "jobs"]);
	const [filters, setFilters] = useState<JobsFilters>(initialFilters);
	const [jobs, setJobs] = useState<PartialJob[]>([]);
	const [displayedJobsCount, setDisplayedJobsCount] = useState(JOBS_TO_SHOW_INCREMENT);
	const { error, filterJobs, filterOptions, loading } = useJobsContext();

	/**
	 * Reset button visibility logic
	 * `jobFilters` are sticky filters set in the associated Contentful entry
	 * `filters` are user filters, which won't override jobFilters
	 * So we only want a reset button when non-sticky user filters have changed
	 */
	const hideResetButton =
		filters.department === initialFilters.department &&
		filters.country === initialFilters.country &&
		filters.location === initialFilters.location;

	const handleSelectChange = useCallback(
		(key: string, value: string) => {
			// If country changes, we need to reset the location filter
			// e.g. If Spain and Madrid were selected and we switch to Germany
			// Otherwise filter as normal
			const newFilters =
				key === "country"
					? { ...filters, location: "", country: value }
					: { ...filters, [key]: value };
			setFilters(newFilters);
			setJobs(filterJobs(newFilters));
			setDisplayedJobsCount(JOBS_TO_SHOW_INCREMENT);
		},
		[filterJobs, filters]
	);

	// Returns location options that belong to a selected country
	const getFilteredLocations = (country: string) => {
		if (!country) {
			return filterOptions.locations;
		}
		return filterOptions.locations.filter(location => location.locationFull.includes(country));
	};

	const resetFilters = () => {
		const newFilters = {
			...initialFilters,
			country: initialFilters?.country || "",
			department: initialFilters?.department || "",
			location: initialFilters?.location || "",
		};
		setFilters(newFilters);
		setJobs(filterJobs(newFilters));
		setDisplayedJobsCount(JOBS_TO_SHOW_INCREMENT);
	};

	const displayedJobs = jobs.slice(0, displayedJobsCount);
	const remainingJobsCount = jobs.length - displayedJobsCount;
	const showMoreJobs = () => {
		setDisplayedJobsCount(prevCount => prevCount + JOBS_TO_SHOW_INCREMENT);
	};

	const departmentOptions = filterOptions.departments
		.filter(department => !initialFilters.excludedDepartments.includes(department))
		.map(department => ({
			value: department,
			name: t(`jobs:departments.${department}`),
		}));

	useEffect(() => {
		if (initialFilters) {
			setJobs(filterJobs(initialFilters));
		}
	}, [filterJobs, initialFilters]);

	return (
		<Grid>
			<Row>
				<Column s={1} l={2} />
				<Column m={6} l={8}>
					<SlotHeader
						centered
						centeredOnMobile
						headline={headline}
						subtitle={description}
					/>
				</Column>
			</Row>
			<Row>
				<Column s={0} l={1} />
				<Column l={10}>
					{/* If filters pre-selected in Contentful, we don't show filters */}
					<StyledFilters>
						{!initialFilters?.department && (
							<Select
								name="departments"
								options={departmentOptions}
								onSelect={value =>
									handleSelectChange("department", value as string)
								}
								placeholderText={t("jobs:filters.all-departments")}
								size="LG"
								value={filters?.department ?? ""}
							/>
						)}
						{!initialFilters?.country && (
							<Select
								name="countries"
								options={filterOptions.countries.map(country => ({
									value: country,
									name: t(`jobs:countries.${country}`),
								}))}
								onSelect={value => handleSelectChange("country", value as string)}
								placeholderText={t("jobs:filters.all-countries")}
								size="LG"
								value={filters?.country ?? ""}
							/>
						)}
						{!initialFilters?.location && (
							<Select
								name="locations"
								options={getFilteredLocations(filters.country).map(
									({ locationShort }) => ({
										value: locationShort,
										name: locationShort,
									})
								)}
								onSelect={value => handleSelectChange("location", value as string)}
								placeholderText={t("jobs:filters.all-locations")}
								size="LG"
								value={filters?.location ?? ""}
							/>
						)}
						{!hideResetButton && (
							<TextButton
								useMobileButtonBehaviour
								onClick={resetFilters}
								size="LG"
								variant={TextButtonVariants.secondaryText}
							>
								{t("jobs:filters.reset")}
							</TextButton>
						)}
					</StyledFilters>
					{error && (
						<StyledErrorText centered centeredOnMobile>
							{t("common:generic-error")}
						</StyledErrorText>
					)}
					{!loading && jobs?.length === 0 && <NoJobsFound />}
					<JobList jobs={displayedJobs} totalCount={jobs.length} />

					{remainingJobsCount > 0 && (
						<StyledLoadMoreButton
							useMobileButtonBehaviour
							onClick={showMoreJobs}
							size="LG"
							variant={ButtonVariants.tertiary}
						>
							{t("jobs:filters.show-more", {
								count: Math.min(remainingJobsCount, JOBS_TO_SHOW_INCREMENT),
							})}
						</StyledLoadMoreButton>
					)}
				</Column>
			</Row>
		</Grid>
	);
};
