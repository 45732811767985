import React from "react";

import Image from "next/image";

import { PlainImageText } from "@/components/contentful/components/image-text/components/plain-image-text";
import { VisualImageText } from "@/components/contentful/components/image-text/components/visual-image-text";
import type { ImageTextComponentProps, ImageTextProps } from "@/components/contentful/types";
import { AssetFormat } from "@/types/contentful-images";

import { StyledImageWrapper } from "./styled";

export const ImageTextComponent: React.FC<ImageTextComponentProps> = ({
	componentDisplayType,
	...props
}) => {
	switch (componentDisplayType) {
		case "visual":
			return <VisualImageText {...props} />;
		case "image-only": {
			const {
				imagesCollection: {
					items: [image],
				},
			} = props;
			return (
				<StyledImageWrapper>
					<Image
						src={`${image.url}?w=2000&q=75&fm=${AssetFormat.webp}`}
						alt={image.description ?? ""}
						layout="responsive"
						width={image.width}
						height={image.height}
						quality={75}
						sizes="90vw"
						loading="lazy"
					/>
				</StyledImageWrapper>
			);
		}

		case "plain":
		default:
			return <PlainImageText {...props} />;
	}
};

export const ImageText: React.ComponentType<ImageTextProps> = props => {
	return <ImageTextComponent {...props} />;
};

export default ImageText;
