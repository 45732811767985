import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Column, Grid, Row } from "@/components/grid";
import type { PropsWithTheme } from "@/theme";

const WarningMessage = styled.div<PropsWithTheme>`
	padding: var(--spacing-m);
	${({ theme: { palette } }) => css`
		background: ${palette.freeze[0]};
		color: ${palette.red[500]};
		box-shadow: 0 0 0 2px ${palette.red[500]};
		border-radius: 5px;
	`};
`;

const Code = styled.code<PropsWithTheme>`
	padding: 4px;
	${({ theme: { palette } }) => css`
		background: ${palette.freeze[1000]};
		color: ${palette.freeze[0]};
		border-radius: 5px;
	`};
`;

export const ContentfulPlaceholder: React.FC<{ id?: string; slot?: string }> = ({ slot, id }) => {
	return (
		<Grid overflow>
			<Row>
				<Column>
					<WarningMessage>
						Incomplete slot: <Code>{slot}</Code> with id: <Code>{id}</Code>
						<br />
						<br />
						<a
							href={`https://app.contentful.com/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/entries/${id}`}
							target="_blank"
							rel="noreferrer"
						>
							Edit component
						</a>
					</WarningMessage>
				</Column>
			</Row>
		</Grid>
	);
};
