import React from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { Column, Grid, Hidden, Row } from "@/components/grid";
import { Spacer } from "@/components/layout/components";
import { StyledImage, StyledLink } from "@/components/slot/components";
import { Typography } from "@/components/typography/typography";
import type { Locale } from "@/contentful/content-model/types";
import { FONT_WEIGHT } from "@/theme";
import type { SingleLink as SingleLinkProps } from "@/types/contentful-api";
import { getLinkFromEntryLink } from "@/utils/urls";

export const SingleLink: React.FC<SingleLinkProps> = ({
	entryLink,
	featuredImage,
	headline,
	description,
	linkText,
}) => {
	const { locale } = useRouter();
	const linkConfig = getLinkFromEntryLink(entryLink, locale as Locale);

	return (
		<Row>
			{featuredImage && (
				<Column m={3}>
					<StyledImage
						loading="lazy"
						src={`${featuredImage.url}?fl=progressive&fm=jpg&q=75&fit=fill&f=faces&w=800&h=592`}
						alt={featuredImage.description ?? ""}
					/>
				</Column>
			)}
			<Column m={featuredImage ? 5 : 8}>
				<Hidden s>
					<Spacer spacing="xxs" />
				</Hidden>
				<Typography bottom component="h5" weight={FONT_WEIGHT.medium}>
					{headline}
				</Typography>
				<Typography light>{description}</Typography>
				<Link
					passHref
					href={linkConfig.href}
					as={linkConfig.as}
					locale={locale}
					prefetch={false}
				>
					<StyledLink>
						<Typography>{linkText}</Typography>
					</StyledLink>
				</Link>
			</Column>
		</Row>
	);
};

export const SingleLinkSlot: React.FC<SingleLinkProps> = props => {
	return (
		<Grid>
			<SingleLink {...props} />
		</Grid>
	);
};
