import React from "react";

import { useTranslation } from "next-i18next";

import { useRouter } from "next/router";

import { LinkButton } from "@/components/buttons";
import { ButtonVariants } from "@/components/buttons/types";
import { Hidden } from "@/components/grid";
import { Typography } from "@/components/typography/typography";
import { FONT_WEIGHT, TypographyVariant } from "@/theme";
import { getJobUrl } from "@/utils/greenhouse";

import SearchIllustration from "../../../../../public/assets/illustrations/search.svg";
import ArchiveBoxIcon from "../../../../../public/assets/svg-icons/i-archive-box.svg";
import BriefcaseIcon from "../../../../../public/assets/svg-icons/i-briefcase.svg";
import LocationIcon from "../../../../../public/assets/svg-icons/i-location.svg";

import { StyledJobCard, StyledJobList, StyledNoJobsFound, JobTag, JobTags } from "./styled";
import type { JobCardProps, JobListProps } from "./types";

export const JobList = ({ jobs, totalCount }: JobListProps) => {
	const { t } = useTranslation("jobs");

	if (jobs?.length === 0) {
		return null;
	}

	return (
		<>
			<Typography variant={TypographyVariant.headlineSerifXXS}>
				{t("jobs:filters.job-count", { count: totalCount })}
			</Typography>
			<StyledJobList>
				{jobs.map(job => (
					<li key={job.id}>
						<JobCard job={job} />
					</li>
				))}
			</StyledJobList>
		</>
	);
};

export const NoJobsFound = () => {
	const { t } = useTranslation("jobs");

	return (
		<StyledNoJobsFound>
			<SearchIllustration aria-hidden="true" height="56" width="56" />
			<Typography
				tight
				variant={TypographyVariant.headlineSerifXXS}
				weight={FONT_WEIGHT.medium}
			>
				{t("jobs:filters.no-results-headline")}
			</Typography>
			<Typography tight variant={TypographyVariant.bodyLG}>
				{t("jobs:filters.no-results-description")}
			</Typography>
		</StyledNoJobsFound>
	);
};

export const JobCard = ({ job }: JobCardProps) => {
	const { locale } = useRouter();
	const { t } = useTranslation("jobs");
	const { departments, id, metadata, title, location } = job;

	// The matched string "Employment Type" is a specific Greenhouse value
	const jobType = metadata.filter(item => item.name === "Employment Type");

	const jobPostUrl = getJobUrl({ locale, id, title });

	return (
		<StyledJobCard href={jobPostUrl}>
			<div>
				<Typography tight variant={TypographyVariant.bodyLG} weight={FONT_WEIGHT.medium}>
					{title}
				</Typography>
				<JobTags>
					<JobTag
						light
						tight
						variant={TypographyVariant.bodyMD}
						weight={FONT_WEIGHT.light}
					>
						<LocationIcon />
						{location?.name?.split(",")?.[0]}
					</JobTag>
					<JobTag
						light
						tight
						variant={TypographyVariant.bodyMD}
						weight={FONT_WEIGHT.light}
					>
						<BriefcaseIcon />
						{jobType?.[0].value}
					</JobTag>
					<JobTag
						light
						tight
						variant={TypographyVariant.bodyMD}
						weight={FONT_WEIGHT.light}
					>
						<ArchiveBoxIcon />
						{departments?.[0].name}
					</JobTag>
				</JobTags>
			</div>
			<Hidden m l>
				<LinkButton size="LG" variant={ButtonVariants.secondary}>
					{t("jobs:see-job")}
				</LinkButton>
			</Hidden>
		</StyledJobCard>
	);
};
