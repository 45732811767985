import React from "react";

import { StyledInfoTextBox, StyledTypography } from "@/components/boxes/styled";
import type { BoxesComponentProps } from "@/components/boxes/types";
import { BreakLines } from "@/components/break-lines";
import { Alignment, Row } from "@/components/grid";
import { Typography } from "@/components/typography/typography";
import { TypographyVariant } from "@/theme";
import type { InfoText } from "@/types/contentful-api";

export const InfoSectionBoxes: React.VFC<BoxesComponentProps<InfoText[]>> = ({ dark, items }) => {
	return (
		<Row justify={Alignment.center}>
			{items.map(item => {
				const { headline, subtitle } = item;
				return (
					<StyledInfoTextBox key={item.sys.id} m={2} l={3}>
						<Typography tight component="p" variant={TypographyVariant.headlineSerifLG}>
							<BreakLines text={headline} />
						</Typography>
						<StyledTypography tight dark={dark}>
							<BreakLines text={subtitle} />
						</StyledTypography>
					</StyledInfoTextBox>
				);
			})}
		</Row>
	);
};
