import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { SlotWrapper } from "@/components/slot/components";
import type { PropsWithTheme } from "@/theme";
import { BackgroundColor } from "@/theme";

import { getBackgroundColor } from "../utils";

export const ColoredSlotWrapper = styled(SlotWrapper)<
	{
		primary?: BackgroundColor;
		secondary?: BackgroundColor;
	} & PropsWithTheme
>`
	width: 100%;
	${({ theme: { palette, mq }, primary, secondary }) => css`
		background-color: ${getBackgroundColor(primary)};
		color: ${primary === BackgroundColor.black ? palette.freeze[0] : palette.freeze[1000]};
		@media ${mq.l} {
			background-image: linear-gradient(
				to top,
				${getBackgroundColor(secondary)} calc(var(--spacing-xxl) + var(--spacing-l)),
				${getBackgroundColor(primary)} calc(var(--spacing-xxl) + var(--spacing-l)),
				${getBackgroundColor(primary)}
			);
		}
	`};
`;
