import React from "react";

import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import { PriceLabel } from "@/components/contentful/components/property-list/components";
import { Hidden, Stage } from "@/components/grid";
import { Spacer } from "@/components/layout/components";
import { Typography } from "@/components/typography/typography";
import dirs from "@/contentful/content-model/dirs";
import { Dirs } from "@/contentful/content-model/types";
import { TypographyVariant } from "@/theme";
import type { Property } from "@/types/contentful-api";
import { PropertyOptions } from "@/types/property";
import { imageLoaderFaceFill } from "@/utils/image";

import { CollageSlot, CollageSlots, Inner, InnerWidth, Overlay } from "./styled";

interface PropertyListCollageProps {
	properties: Property[];
}

export const PropertyListCollage: React.VFC<PropertyListCollageProps> = ({ properties }) => {
	const { locale } = useRouter();

	const filteredProperties = properties
		// Missing featuredImage can happen when editors delete images but don't remove them from the Property entry
		.filter(
			item =>
				Boolean(item) && Boolean(item.exportedPropertyData) && Boolean(item.featuredImage)
		);
	const slotCount = filteredProperties.length;

	return (
		<Stage>
			<CollageSlots data-test-id="collage" slotCount={slotCount}>
				{filteredProperties.map(
					(
						{
							sys: { id },
							entryLink: { breadcrumb },
							featuredImage: { url, description },
							salesStatus,
							options,
							hidePrice,
							exportedPropertyData: {
								data: { headline, price },
							},
						},
						index
					) => {
						const isProject = options?.includes(PropertyOptions.Project);

						const propertyUrl = `/${dirs[Dirs.property].dir[locale] as string}/${id}/`;

						let left;
						if (slotCount <= 3) {
							left = [true, false, false];
						} else if (slotCount === 4) {
							left = [true, false, true, false];
						} else {
							left = [true, false, false, true, false];
						}

						return (
							<CollageSlot key={id} index={index} slotCount={slotCount}>
								<Inner>
									<Image
										loader={imageLoaderFaceFill}
										src={url}
										alt={description ?? ""}
										layout="fill"
										objectFit="cover"
										quality={70}
										sizes="(max-width: 767px) 100vw, 50vw"
										loading="lazy"
									/>
								</Inner>
								<Link passHref href={propertyUrl} locale={locale}>
									<Overlay>
										<InnerWidth left={left[index]}>
											<Hidden l>
												<Typography
													bottom
													component="h3"
													variant={TypographyVariant.headlineSerifSM}
												>
													{headline}
												</Typography>
												<Spacer spacing="xs" />
											</Hidden>
											<Typography tight>
												{`${breadcrumb} - `}
												<PriceLabel
													price={price}
													salesStatus={salesStatus}
													hidePrice={hidePrice}
													isProject={isProject}
												/>
											</Typography>
										</InnerWidth>
									</Overlay>
								</Link>
							</CollageSlot>
						);
					}
				)}
			</CollageSlots>
		</Stage>
	);
};
