import React from "react";

import { useTranslation } from "next-i18next";

import { useRouter } from "next/router";

import { Column, Grid, Row } from "@/components/grid";
import { Table } from "@/components/table";
import type { PropertyTableColumn } from "@/components/table/types";
import { Typography } from "@/components/typography/typography";
import { TypographyVariant } from "@/theme";
import type { Table as TableSlotProps } from "@/types/contentful-api";
import { formatNumber, formatPrice } from "@/utils/number";

export const TableSlot = (props: TableSlotProps) => {
	const { locale } = useRouter();
	const { t } = useTranslation("property");

	const propertyTableColumns: PropertyTableColumn[] = [
		{ id: "id", value: t("property:apartment") },
		{ id: "story", value: t("property:floor") },
		{ id: "rooms", value: t("property:rooms") },
		{ id: "area", value: t("property:area") },
		{ id: "price", value: t("property:purchase-price") },
		{ id: "state", value: t("property:status") },
		{ id: "pdf", value: t("property:floor-plans") },
	];

	return (
		<Grid>
			<Row>
				{props.headline && (
					<Column>
						<Typography component="h2" variant={TypographyVariant.headlineSerifLG}>
							{props.headline}
						</Typography>
					</Column>
				)}
				{props.description && (
					<Column l={8}>
						<Typography>{props.description}</Typography>
					</Column>
				)}
				<Column>
					<Table
						columns={propertyTableColumns}
						rows={props.rowsCollection.items.map(row => ({
							...row,
							id: row.apartmentId,
							rooms: formatNumber(row.rooms, locale),
							area: (
								<>
									{formatNumber(row.area, locale)} m<sup>2</sup>
								</>
							),
							price:
								row.price &&
								row.state !== "verkauft" &&
								formatPrice(row.price, "eur", locale),
							pdf: row.pdf && (
								<a download href={row.pdf.url} target="_blank" rel="noreferrer">
									PDF
								</a>
							),
						}))}
					/>
				</Column>
			</Row>
		</Grid>
	);
};
