import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { MotionProps, Variants } from "framer-motion";
import { motion } from "framer-motion";

import type { RowProps } from "@/components/grid";
import { SnapColumn } from "@/components/grid/extensions";
import { ScrollableRow, useViewport } from "@/design-system/organisms/slideshow-x/rewrites";
import type { PropsWithTheme } from "@/theme";

export const StyledSlideshowXWrapper = styled(ScrollableRow)<PropsWithTheme & RowProps>`
	position: relative;
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			overflow: hidden;
		}
	`};
`;

export const StyledSlideshowXSlideWrapper = styled(SnapColumn, {
	shouldForwardProp: (prop: string) => prop !== "shouldClip",
})<PropsWithTheme & { shouldClip?: boolean }>`
	scroll-snap-align: center;
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			scroll-snap-align: unset;
			visibility: hidden;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;

			&:first-of-type {
				position: relative;
			}
		}
	`};
	${({ shouldClip }) =>
		shouldClip &&
		css`
			overflow: hidden;
		`};
`;

export const StyledSlideshowXSlideSection = styled.div<{
	shouldClip?: boolean;
}>`
	visibility: hidden;
	${({ shouldClip, theme: { tokens } }) =>
		shouldClip &&
		css`
			overflow: hidden;
			border-radius: ${tokens.borderRadius["3xl"]};
		`};
`;

export const AnimatedSlideshowXSlide = styled(motion.div)`
	visibility: visible;
	position: relative;
`;

export const StyledSlideshowXMediaWrapper = styled.div<StyledSlideshowXMediaWrapperProps>`
	position: relative;
	${({ aspectRatio, theme: { tokens } }) => css`
		padding-bottom: ${100 * aspectRatio}%;
		overflow: hidden;
		border-radius: ${tokens.borderRadius["3xl"]};
	`};
`;

export const StyledSlideshowDots = styled.div<PropsWithTheme & { aspectRatio: number }>`
	visibility: hidden;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	${({ theme: { mq }, aspectRatio }) => css`
		padding-bottom: ${100 * aspectRatio}%;

		@media ${mq.m} {
			display: none;
		}
	`};
`;

export const StyledDots = styled.div<PropsWithTheme>`
	display: flex;
	visibility: visible;
	position: absolute;
	z-index: 2;
	top: 100%;
	right: 0;
	left: 0;
	align-content: center;
	align-items: center;
	justify-content: center;
`;

export const StyledDot = styled.div<PropsWithTheme & { active?: boolean }>`
	width: var(--spacing-xxxs);
	height: var(--spacing-xxxs);
	margin: var(--spacing-xxxs) 4px;
	border-radius: 50%;
	${({ active }) => css`
		background: currentColor;
		opacity: ${active ? 1 : 0.5};
	`};
`;

export const StyledSlideshowXMedia = styled.div`
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	img {
		pointer-events: none;
		user-select: none;
	}
`;
export interface StyledSlideshowXMediaWrapperProps {
	aspectRatio?: number;
}

export interface SlideshowXMediaProps extends React.HTMLAttributes<HTMLDivElement> {
	aspectRatio?: number;
}

export const SlideshowXMedia = React.forwardRef<HTMLDivElement, SlideshowXMediaProps>(
	({ children, aspectRatio }, ref) => {
		return (
			<StyledSlideshowXMediaWrapper ref={ref} aspectRatio={aspectRatio}>
				<StyledSlideshowXMedia>{children}</StyledSlideshowXMedia>
			</StyledSlideshowXMediaWrapper>
		);
	}
);

SlideshowXMedia.defaultProps = {
	aspectRatio: 4 / 3,
};

export interface SlideshowXSlideProps extends MotionProps {
	current?: boolean;
	previous?: boolean;
	next?: boolean;
	variants?: Variants;
	children: React.ReactNode;
}

const defaultVariants = {
	current: {
		opacity: 1,
		x: 0,
	},
	previous: {
		opacity: 0,
		x: "-100%",
	},
	next: {
		opacity: 0,
		x: "100%",
	},
};

export const SlideshowXSlide = React.forwardRef<HTMLDivElement, SlideshowXSlideProps>(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	({ children, current, previous, next, variants, ...props }, ref) => {
		const { m } = useViewport();
		return (
			<AnimatedSlideshowXSlide
				{...props}
				ref={ref}
				variants={variants}
				initial={current ? "current" : previous ? "previous" : "next"}
				animate={current || !m ? "current" : previous ? "previous" : "next"}
			>
				{children}
			</AnimatedSlideshowXSlide>
		);
	}
);

SlideshowXSlide.defaultProps = {
	variants: defaultVariants,
};

export const SlideshowX = React.forwardRef<HTMLDivElement, RowProps>(
	({ children, ...props }, ref) => {
		return (
			<StyledSlideshowXWrapper {...props} ref={ref}>
				{children}
			</StyledSlideshowXWrapper>
		);
	}
);

export const StyledControls = styled.div<PropsWithTheme>`
	display: none;
	visibility: hidden;
	position: relative;
	height: 48px;
	transform: translateY(-100%);
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			display: block;
			visibility: visible;
		}
	`}
`;

export const StyledControl = styled.button`
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	border: 0;
	background: white;
	color: black;

	&[disabled] {
		color: #ccc;
		pointer-events: none;
	}
`;
