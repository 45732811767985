import React from "react";

import { Spacer } from "@/components/layout/components";
import { Typography } from "@/components/typography/typography";
import { TypographyVariant } from "@/theme";

export const Headline: React.VFC<{ headline?: string }> = ({ headline }) => {
	return headline ? (
		<>
			<Typography bottom centered component="h2" variant={TypographyVariant.headlineSerifLG}>
				{headline}
			</Typography>
			<Spacer spacing="xxs" />
		</>
	) : null;
};
