import React from "react";

import { Column, Grid, Row } from "@/components/grid";
import { CenterColumn } from "@/components/grid/extensions";
import { Markdown } from "@/components/markdown";
import { Typography } from "@/components/typography/typography";
import { TypographyVariant } from "@/theme";
import type { Markdown as MarkdownSlotProps } from "@/types/contentful-api";

export const MarkdownSlot = (props: MarkdownSlotProps) => {
	const { layoutType, headline, centered, ...rest } = props;

	const columnOffsetCount = centered && layoutType === "medium" ? 2 : 0;
	const columnCount =
		!layoutType || layoutType === "full" ? 12 : centered && layoutType === "medium" ? 8 : 7;
	const shouldCenterActionButton =
		!layoutType || layoutType === "full" || (centered && layoutType === "medium");

	const ColumnComponent = centered ? CenterColumn : Column;

	return (
		<Grid>
			<Row>
				<Column s={0} l={columnOffsetCount} />
				<ColumnComponent l={columnCount}>
					{headline && (
						<Typography component="h2" variant={TypographyVariant.headlineSerifLG}>
							{headline}
						</Typography>
					)}
					<Markdown centerButton={shouldCenterActionButton} {...rest} />
				</ColumnComponent>
			</Row>
		</Grid>
	);
};
