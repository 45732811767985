import React from "react";

import { format } from "date-fns";
import { de } from "date-fns/locale";

import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";

import { Breadcrumbs } from "@/components/breadcrumbs";
import { BreakLines } from "@/components/break-lines";
import { ContentfulPlaceholder } from "@/components/contentful-placeholder";
import { Column, Grid, Hidden, Row } from "@/components/grid";
import { ContentfulNextImage } from "@/components/image";
import Layout from "@/components/layout";
import { Spacer } from "@/components/layout/components";
import { BlogMarkdown } from "@/components/markdown";
import { Slot } from "@/components/slot";
import { SlotWrapper } from "@/components/slot/components";
import { SingleLink } from "@/components/slot/slots/single-link";
import { Typography } from "@/components/typography/typography";
import dirs from "@/contentful/content-model/dirs";
import type { Locale } from "@/contentful/content-model/types";
import { Dirs } from "@/contentful/content-model/types";
import { BreadcrumbsProvider } from "@/context/breadcrumbs";
import { Author } from "@/design-system/molecules/person";
import { FONT_WEIGHT, TypographyVariant } from "@/theme";
import { AssetFit, AssetFocus, AssetFormat } from "@/types/contentful-images";
import { staticHeaderImageLoader } from "@/utils/image";

import {
	StyledAuthorWrapper,
	StyledImageWrapper,
	StyledMetaInfo,
	StyledMetaInfoItem,
	Visual,
	VisualHeadline,
} from "./styled";
import type { BlogPostProps } from "./types";

const MetaInfo = ({ categories, date }) => {
	return (
		<StyledMetaInfo>
			{categories.map(({ sys: { id }, description }) => (
				<StyledMetaInfoItem key={id} data-test-id="blog-post:category">
					{description}
				</StyledMetaInfoItem>
			))}
			{date && <StyledMetaInfoItem>{date}</StyledMetaInfoItem>}
		</StyledMetaInfo>
	);
};

export const Template = ({ data, url }: BlogPostProps) => {
	const { locale } = useRouter();

	const {
		sys,
		meta,
		headline,
		slug,
		date = "2020-12-21T14:02:00.000Z",
		author,
		showDate,
		breadcrumb,
		featuredImage,
		slotsCollection,
		categoriesCollection,
	} = data.page;

	const breadcrumbs = React.useMemo(
		() => [
			{
				title: dirs[Dirs.ROOT].breadcrumb[locale],
				href: "/",
			},
			{
				title: dirs[Dirs.blog].breadcrumb[locale],
				href: `/${dirs[Dirs.blog].dir[locale] as string}/`,
			},
			{
				title: breadcrumb,
				href: `/${dirs[Dirs.blog].dir[locale] as string}/${slug}/`,
			},
		],
		[breadcrumb, locale, slug]
	);

	const slots = React.useMemo(() => slotsCollection.items.filter(Boolean), [slotsCollection]);

	const categories = React.useMemo(
		() => categoriesCollection.items.filter(Boolean),
		[categoriesCollection]
	);

	const dateToShow = React.useMemo(() => {
		switch (showDate) {
			case "created":
			case "updated":
				return sys.firstPublishedAt || date;
			case "hide":
				return null;
			case "custom":
			default:
				return date;
		}
	}, [showDate, date, sys]);

	return (
		<Layout data={data} meta={meta} url={url}>
			<Head>
				<meta name="last-publish" content={sys.publishedAt} />
			</Head>
			<BreadcrumbsProvider value={breadcrumbs}>
				<Visual>
					{featuredImage && (
						<ContentfulNextImage
							priority
							loader={staticHeaderImageLoader}
							src={featuredImage.url}
							alt={featuredImage.description}
							quality={65}
							layout="fill"
							objectFit="cover"
							f={AssetFocus.center}
							fit={AssetFit.thumb}
							height={900}
						/>
					)}
					<VisualHeadline>
						<Grid>
							<Row>
								<Column l={1} />
								<Column l={9}>
									{categories && (
										<MetaInfo
											categories={categories}
											date={
												dateToShow &&
												format(new Date(dateToShow), "PP", {
													locale: de,
												})
											}
										/>
									)}
									<Spacer spacing="xxs" />
									{headline && (
										<Hidden l>
											<Typography
												centered
												tight
												component="h1"
												data-test-id="blog-post:title"
												variant={TypographyVariant.headlineSerif2XL}
											>
												<BreakLines text={headline} />
											</Typography>
										</Hidden>
									)}
									<Hidden s m>
										<Typography
											tight
											component="h1"
											variant={TypographyVariant.headlineSerif2XL}
										>
											<BreakLines text={headline} />
										</Typography>
									</Hidden>
									{author && (
										<StyledAuthorWrapper>
											<Author
												featuredImage={author.featuredImage}
												name={author.name}
											/>
										</StyledAuthorWrapper>
									)}
								</Column>
							</Row>
						</Grid>
					</VisualHeadline>
				</Visual>
				<Grid>
					<Row>
						<Column l={2} />
						<Column l={9}>
							<Breadcrumbs />
						</Column>
					</Row>
				</Grid>
				{slots?.map(slot => {
					// Specific Markdown handling due to smaller central grid on blog post
					if (slot?.__typename === "Markdown") {
						if (!slot.description) {
							return (
								<ContentfulPlaceholder
									key={slot.sys.id}
									slot={slot?.__typename}
									id={slot.sys.id}
								/>
							);
						}

						return (
							<Grid key={slot.sys.id}>
								<Row>
									<Column l={2} />
									<Column l={8}>
										<SlotWrapper>
											<BlogMarkdown {...slot} />
										</SlotWrapper>
									</Column>
								</Row>
							</Grid>
						);
					}

					// Specific SingleLinkSlot handling due to smaller central grid on blog posts
					if (slot?.__typename === "SingleLink") {
						return (
							<Grid key={slot.sys.id}>
								<Row>
									<Column l={2} />
									<Column l={8}>
										<SlotWrapper>
											<SingleLink {...slot} />
										</SlotWrapper>
									</Column>
								</Row>
							</Grid>
						);
					}

					/**
					 * @ToDo Remove Images as a content-type after editors move away from it
					 */
					if (slot?.__typename === "Images") {
						const [firstImage] = slot.imagesCollection.items;
						if (!firstImage) {
							return (
								<ContentfulPlaceholder
									key={slot.sys.id}
									slot={slot?.__typename}
									id={slot.sys.id}
								/>
							);
						}

						return (
							<SlotWrapper key={slot.sys.id}>
								<Hidden l>
									<Grid>
										<Row>
											<Column l={1} />
											<Column l={10}>
												<StyledImageWrapper>
													<Image
														src={`${firstImage.url}?fm=${AssetFormat.webp}&q=75`}
														alt={firstImage.description}
														height={firstImage.height}
														width={firstImage.width}
														quality={75}
														layout="responsive"
														sizes="70vw"
													/>
												</StyledImageWrapper>
												<Typography
													light
													variant={TypographyVariant.bodySM}
													weight={FONT_WEIGHT.light}
												>
													{firstImage.description}
												</Typography>
											</Column>
										</Row>
									</Grid>
								</Hidden>
								<Hidden s m>
									<Image
										src={`${firstImage.url}?fm=${AssetFormat.webp}&q=75`}
										alt={firstImage.description}
										height={firstImage.height}
										width={firstImage.width}
										quality={75}
										layout="responsive"
									/>
									<Grid>
										<Row>
											<Column />
											<Column>
												{firstImage && (
													<Typography
														light
														weight={FONT_WEIGHT.light}
														variant={TypographyVariant.bodySM}
													>
														{firstImage.description}
													</Typography>
												)}
											</Column>
										</Row>
									</Grid>
								</Hidden>
							</SlotWrapper>
						);
					}

					// Specific remaining slot handling due to smaller central grid on blog post
					return (
						<Grid key={slot.sys.id}>
							<Row>
								<Column l={1} />
								<Column l={10}>
									<Slot
										key={slot.sys.id}
										locale={locale as Locale}
										textCollection={data.textCollection}
										{...slot}
									/>
								</Column>
							</Row>
						</Grid>
					);
				})}
			</BreadcrumbsProvider>
			<Spacer spacing="xxl" />
		</Layout>
	);
};
