import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Typography } from "@/components/typography/typography";
import { FONT_WEIGHT, TYPOGRAPHY } from "@/theme";
import type { PropsWithTheme } from "@/theme";

export const StyledFigure = styled.figure<PropsWithTheme>`
	display: flex;
	flex-direction: column;
	margin: 6px 4px;
	height: 100%;
	padding: var(--spacing-xxs) 20px;
	${({ theme: { mq, palette, tokens } }) => css`
		background-color: ${palette.freeze[0]};
		border-radius: ${tokens.borderRadius.xl};
		box-shadow: ${tokens.elevation.bottom.MD};
		@media ${mq.m} {
			flex-direction: row;
			max-width: 552px;
			width: 100%;
			padding: var(--spacing-xs);
			gap: var(--spacing-xs);
		}
	`}
`;

export const StyledImageWrapper = styled.div<PropsWithTheme>`
	align-self: center;
	border-radius: 100px;
	width: 190px;
	min-height: 271px;
	overflow: hidden;
	position: relative;
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			align-self: flex-start;
			min-width: 140px;
			min-height: 210px;
		}
	`}
`;

export const StyledFigCaption = styled.figcaption<PropsWithTheme>`
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: var(--spacing-xxxs);
	justify-content: space-between;
	margin-top: var(--spacing-xxs);
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			flex: initial;
			justify-content: initial;
		}
	`}
`;

export const StyledQuote = styled.blockquote<PropsWithTheme>`
	margin: 0;
`;

export const StyledCitation = styled.cite<PropsWithTheme>`
	gap: var(--spacing-xxxs);
	font-style: normal;
`;

export const StyledAdditionalInfo = styled(Typography)<PropsWithTheme>`
	${({ theme: { mq, palette } }) => css`
		color: ${palette.freeze[500]};
		@media ${mq.m} {
			${TYPOGRAPHY.body.MD}
			font-weight: ${FONT_WEIGHT.light};
		}
	`};
`;
