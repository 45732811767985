import React from "react";

import { Card, CardContent } from "@/components/boxes/styled";
import type { BoxesComponentProps } from "@/components/boxes/types";
import { BreakLines } from "@/components/break-lines";
import { Column, Hidden, Row } from "@/components/grid";
import { ScrollableRow, SnapColumn } from "@/components/grid/extensions";
import { Spacer } from "@/components/layout/components";
import { Typography } from "@/components/typography/typography";
import type { IconName } from "@/design-system/atoms/icons";
import { Icon, IconSize } from "@/design-system/atoms/icons";
import type { IconText } from "@/types/contentful-api";

export const InfoTextBoxes: React.VFC<BoxesComponentProps<IconText[]>> = ({ items }) => {
	return (
		<Row>
			<Column l={8}>
				<ScrollableRow>
					{items.map(item => {
						const { icon, headline } = item;
						return (
							<SnapColumn key={item.sys.id} flex s={3} l={2}>
								<Card>
									<CardContent>
										<Icon icon={icon as IconName} size={IconSize.l} />
										<Hidden l>
											<Spacer spacing="xxxs" />
										</Hidden>
										<Typography tight component="h3">
											<BreakLines text={headline} />
										</Typography>
									</CardContent>
								</Card>
							</SnapColumn>
						);
					})}
				</ScrollableRow>
			</Column>
		</Row>
	);
};
