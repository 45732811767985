import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { TYPOGRAPHY } from "@/theme/";

export const StyledGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(min(100%, 180px), 1fr));
	justify-content: center;
	gap: 12px;
`;

export const StyledLocationTile = styled.a`
	padding: 20px;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	text-decoration: none;
	aspect-ratio: 1 / 1;
	width: 100%;
	height: auto;
	display: block;
	${TYPOGRAPHY.headlines.sans.XXS}
	${({ theme: { mq, palette, tokens } }) => css`
		border-radius: ${tokens.borderRadius.xl};
		color: ${palette.freeze[0]};

		div,
		img,
		svg {
			transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
		}

		div {
			transform-origin: top left;
		}

		img {
			transform: scale(1);
			z-index: -1;
		}

		svg {
			opacity: 0;
			transform: translate3d(0, 25px, 0);
		}

		::after {
			background: linear-gradient(
				165.61deg,
				rgba(27, 76, 103, 0.4) 9.81%,
				rgba(27, 76, 103, 0) 50.86%
			);
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
		}

		@media ${mq.m} {
			aspect-ratio: 216 / 287;
			&:hover {
				div {
					font-size: 120%;
				}

				img {
					transform: scale(1.07);
				}

				svg {
					opacity: 1;
					transform: translate3d(0, 5px, 0);
				}
			}
		}
	`};
`;
