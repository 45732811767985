import React from "react";

import { Column, Hidden, Row } from "@/components/grid";
import { ImageCard } from "@/components/image-card";
import { Spacer } from "@/components/layout/components";
import { Typography } from "@/components/typography/typography";
import { TypographyVariant } from "@/theme";
import { JobDepartment } from "@/types/person";

import { WrappingComponent } from "./components";
import { ArrowSection, FlexArrow, FlexTypography, StyledLinkHintIcon } from "./styled";
import type { PeopleProps } from "./types";

export const People = ({ locale, team }: PeopleProps) => {
	return (
		<Row>
			{team.map(
				({
					disablePage,
					featuredImage,
					headquarter,
					jobDepartment,
					jobPosition,
					name,
					slotsCollection,
					slug,
					sys: { id },
					teamCollection: { items: teamItems },
				}) => {
					const shouldLink =
						jobDepartment === JobDepartment.realEstate &&
						slotsCollection.items.length > 0 &&
						!disablePage;

					return (
						<Column key={id} s={4} l={3}>
							<WrappingComponent
								shouldLink={shouldLink}
								locale={locale}
								headquarterSlug={headquarter?.slug}
								agentSlug={slug}
							>
								<ImageCard vertical image={featuredImage}>
									<Typography
										tight
										component="h2"
										variant={TypographyVariant.headlineSansXXS}
									>
										{name}
									</Typography>
									<Typography tight>{jobPosition}</Typography>
									<ArrowSection>
										{teamItems.length > 0 && (
											<FlexTypography tight>
												{teamItems
													.filter(Boolean)
													.map(({ name }) => name)
													.join(", ")}
											</FlexTypography>
										)}
										{shouldLink && (
											<FlexArrow>
												<StyledLinkHintIcon icon="arrowRight" />
											</FlexArrow>
										)}
									</ArrowSection>
								</ImageCard>
							</WrappingComponent>
							<Hidden s l>
								<Spacer spacing="xs" />
							</Hidden>
							<Hidden m>
								<Spacer spacing="xxs" />
							</Hidden>
						</Column>
					);
				}
			)}
		</Row>
	);
};
