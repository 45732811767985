import styled from "@emotion/styled";

import { Typography } from "@/components/typography/typography";
import { Icon } from "@/design-system/atoms/icons";

export const StyledLinkHintIcon = styled(Icon)`
	margin-left: var(--spacing-xxxs);
`;

export const ArrowSection = styled.div`
	display: flex;
`;

export const FlexTypography = styled(Typography)`
	flex: 1;
`;

export const FlexArrow = styled.div`
	display: inline-flex;
	align-self: flex-end;
`;
