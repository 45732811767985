import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Button } from "@/components/buttons";
import { Typography } from "@/components/typography/typography";
import type { PropsWithTheme } from "@/theme";

export const StyledErrorText = styled(Typography)<PropsWithTheme>`
	${({ theme: { palette } }) => css`
		color: ${palette.red[500]};
	`};
`;

export const StyledJobCard = styled.a<PropsWithTheme>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 16px;
	text-decoration: none;
	cursor: default;
	${({ theme: { tokens, palette } }) => css`
		background: ${palette.freeze[0]};
		border: 1px solid ${palette.freeze[200]};
		border-radius: ${tokens.borderRadius.xl};
		box-shadow: ${tokens.elevation.bottom.XS};
	`};
`;

export const StyledJobList = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 12px;
	list-style: none;
	padding: 0;
	margin: 0;

	> li:last-of-type {
		margin-bottom: 5px;
	}
`;

export const StyledNoJobsFound = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 36px;
	gap: 4px;

	> svg {
		margin-bottom: 16px;
	}
`;

export const JobTags = styled.div`
	display: flex;
	flex-wrap: wrap;
	column-gap: 24px;
	row-gap: 8px;
	margin-top: 6px;
`;

export const JobTag = styled(Typography)<PropsWithTheme>`
	display: flex;
	flex-shrink: 0;
	gap: 4px;
	${({ theme: { palette } }) => css`
		> svg {
			color: ${palette.freeze[500]};
			height: 18px;
			width: 18px;
		}
	`};
`;

export const StyledFilters = styled.div<PropsWithTheme>`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 32px;
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			flex-direction: row;
			margin: 20px 0;
			gap: 12px;
		}
	`};
`;

export const StyledLoadMoreButton = styled(Button)`
	margin: var(--spacing-xxs) auto 0;
`;
