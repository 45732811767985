import React from "react";

import { Markdown } from "@/components/markdown";
import { Typography } from "@/components/typography/typography";
import { Accordion as EvernestAccordion } from "@/design-system/molecules/accordion";
import { TypographyVariant } from "@/theme";
import type { Markdown as MarkdownProps } from "@/types/contentful-api";
import { getId } from "@/utils/id";

// TODO: proptypes are missing
export const AccordionHeader = ({ children, ...props }) => (
	<Typography {...props} tight component="h2" variant={TypographyVariant.headlineSansXXS}>
		{children}
	</Typography>
);

export const MarkdownAccordion: React.VFC<MarkdownProps & { id: string }> = props => {
	const { id, headline, ...rest } = props;

	return (
		<EvernestAccordion headerComponent={AccordionHeader} id={getId(id)} title={headline}>
			<Markdown {...rest} />
		</EvernestAccordion>
	);
};
