import React from "react";

import Link from "next/link";

import { ImageCard, ImageCardLink } from "@/components/image-card/components";
import dirs from "@/contentful/content-model/dirs";
import { Dirs } from "@/contentful/content-model/types";

import type { WrappingComponentProps } from "./types";

export const WrappingComponent = ({
	children,
	shouldLink,
	locale,
	headquarterSlug,
	agentSlug,
}: WrappingComponentProps) => {
	if (!shouldLink || !headquarterSlug || !agentSlug) {
		return <ImageCard>{children}</ImageCard>;
	}
	return (
		<Link
			passHref
			href={`/${dirs[Dirs.ourAgents].dir[locale]}/${headquarterSlug}/${agentSlug}`}
			locale={locale}
		>
			<ImageCardLink>{children}</ImageCardLink>
		</Link>
	);
};
