import React from "react";

import type { StructuredDataProps } from "./types";

/**
 * Use this component to add structured data to any page
 * We use the Google recommended JSON-LD method https://json-ld.org/
 * You can validate your schema at https://developers.google.com/search/docs/appearance/structured-data
 */

export const StructuredData = ({ id, data }: StructuredDataProps) => {
	return (
		// This is not a next/script tag as that invalidates the JSON-LD formatting
		<script
			async
			id={`${id}-jsonld`}
			type="application/ld+json"
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{
				__html: JSON.stringify(data),
			}}
		/>
	);
};
