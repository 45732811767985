import React from "react";

import Video from "@/components/contentful/components/video";
import { Column, Grid, Row } from "@/components/grid";
import { Typography } from "@/components/typography/typography";
import { TypographyVariant } from "@/theme";
import type { Video as VideoSlotProps } from "@/types/contentful-api";
import { VideoType } from "@/types/video";

export const VideoSlot: React.FC<VideoSlotProps> = ({ videoUrl, headline }) => (
	<Grid>
		<Row>
			<Column>
				{headline && (
					<Typography centered component="h2" variant={TypographyVariant.headlineSerifLG}>
						{headline.trim()}
					</Typography>
				)}
				<Video
					videoId={videoUrl.replace(
						/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&\s]+)/,
						"$1"
					)}
					type={VideoType.youtube}
				/>
			</Column>
		</Row>
	</Grid>
);
