import React from "react";

import type { UrlObject } from "url";

import Image from "next/image";
import Link from "next/link";

import { BreakLines } from "@/components/break-lines";
import { Typography } from "@/components/typography/typography";
import { ZOOM_LEVEL } from "@/constants/maps";
import dirs from "@/contentful/content-model/dirs";
import type { Locale } from "@/contentful/content-model/types";
import { Dirs } from "@/contentful/content-model/types";
import { useTrackFeature } from "@/hooks/tag-manager";
import { TypographyVariant } from "@/theme/types";
import type { MapLocationLinks, MapLocationLinksLocationsItem } from "@/types/contentful-api";
import { AssetFit, AssetFocus, AssetFormat } from "@/types/contentful-images";

import ArrowRightIcon from "../../../../../public/assets/svg-icons/i-arrow-right.svg";

import { StyledGrid, StyledLocationTile } from "./styled";

type Query = {
	lat: number;
	lng: number;
	zoom: number;
};

const getDetails = (location: MapLocationLinksLocationsItem) => {
	switch (location?.__typename) {
		case "City":
		case "District":
			const image = location?.featuredImagePortrait ?? location?.featuredImage;
			return {
				image,
				imageAlt:
					image?.description?.length > 0 ? image?.description : location?.breadcrumb,
				name: location?.breadcrumb,
			};
		case "Region":
			return {
				image: location?.featuredImagePortrait,
				imageAlt: location?.featuredImagePortrait?.description ?? location?.region,
				name: location?.region,
			};
		default: {
			return {
				name: null,
				image: null,
				imageAlt: null,
			};
		}
	}
};

const getLocationUrl = (locale: Locale, location: MapLocationLinksLocationsItem): UrlObject => {
	let query: Query;

	switch (location?.__typename) {
		case "City":
			query = {
				lat: location.location?.lat,
				lng: location.location?.lon,
				zoom: location?.zoomLevel ?? ZOOM_LEVEL.high,
			};
			break;
		case "District":
			query = {
				lat: location.geometry?.location?.lat,
				lng: location.geometry?.location?.lng,
				zoom: location?.zoomLevel ?? ZOOM_LEVEL.low,
			};
			break;
		case "Region":
			query = {
				lat: location.location?.lat,
				lng: location.location?.lon,
				zoom: location?.zoomLevel ?? ZOOM_LEVEL.high,
			};
			break;
	}

	const locationUrl = {
		pathname: `/${dirs[Dirs.search].dir[locale]}/`,
		href: `/${dirs[Dirs.search].dir[locale]}/`,
		query,
	};

	return locationUrl;
};

export const MapLocationLinksSlot = (props: MapLocationLinks & { locale: Locale }) => {
	const { headline, subtitle, locale } = props;
	const { items } = props.locationsCollection;
	const trackFeature = useTrackFeature();

	// Filter out null values from linked entries array
	// Contentful returns null for Draft or unpublished linked entries in another entry
	const filteredLocations = items.filter(Boolean);

	return (
		<>
			{headline && (
				<Typography centered component="h2" variant={TypographyVariant.headlineSerifLG}>
					<BreakLines text={headline} />
				</Typography>
			)}
			{subtitle && (
				<Typography bottom={!headline} variant={TypographyVariant.headlineSansXS}>
					{subtitle}
				</Typography>
			)}
			<StyledGrid>
				{filteredLocations.map(location => {
					const locationUrl = getLocationUrl(locale, location);
					const { name, image, imageAlt } = getDetails(location);

					return (
						<Link passHref key={location.sys.id} prefetch={false} href={locationUrl}>
							<StyledLocationTile
								onClick={() => {
									trackFeature("buyer_entry_location_card", name);
								}}
							>
								{image && (
									<Image
										unoptimized
										loading="lazy"
										src={`${image?.url}?w=432&h=574&q=75&fm=${AssetFormat.webp}&f=${AssetFocus.bottom}&fit=${AssetFit.fill}`}
										alt={imageAlt}
										layout="fill"
										objectFit="cover"
										objectPosition="bottom center"
									/>
								)}
								<div>{name}</div>
								<ArrowRightIcon width="24" height="24" />
							</StyledLocationTile>
						</Link>
					);
				})}
			</StyledGrid>
		</>
	);
};
