import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";

export const VisualContent = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
`;

export const WidgetBox = styled.div<PropsWithTheme>`
	display: flex;
	position: relative;
	z-index: 1;
	width: 100%;
	margin-bottom: var(--spacing-xl);
	padding: var(--spacing-xs) var(--spacing-xs);
	${({ theme: { mq, palette, tokens } }) => css`
		background-color: ${palette.freeze[0]};
		box-shadow: ${tokens.elevation.bottom.MD};
		overflow: hidden;
		border-radius: ${tokens.borderRadius["2xl"]};
		@media ${mq.l} {
			padding: var(--spacing-s) var(--spacing-m);
		}
	`};
`;

export const StyledTextArea = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
`;

export const VisualImg = styled.div`
	position: relative;
	height: 440px;
	${({ theme: { tokens } }) => css`
		overflow: hidden;
		border-radius: ${tokens.borderRadius["3xl"]};
	`};
`;
