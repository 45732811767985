import React from "react";

import {
	StyledTable,
	StyledTableWrapper,
	StyledTd,
	StyledTh,
	StyledTr,
} from "@/components/table/styled";
import type { TableProps } from "@/components/table/types";

export const Table: React.FC<TableProps> = ({ columns, rows }) => {
	return (
		<StyledTableWrapper>
			<StyledTable>
				<thead>
					{columns.map(column => (
						<StyledTh key={column.id}>{column.value}</StyledTh>
					))}
				</thead>
				<tbody>
					{rows.map(row => (
						<StyledTr key={row.id}>
							{columns.map(column => (
								<StyledTd key={column.id}>{row[column.id]}</StyledTd>
							))}
						</StyledTr>
					))}
				</tbody>
			</StyledTable>
		</StyledTableWrapper>
	);
};
