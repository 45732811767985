import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Column, Hidden, Row } from "@/components/grid";
import { ASPECT_RATIO } from "@/constants/layout";
import { useMounted } from "@/hooks/loading";
import type { PropsWithTheme } from "@/theme";
import type { VideoProps } from "@/types/video";
import { getVideoLink } from "@/utils/urls";

const StyledVideoWrapper = styled.div<PropsWithTheme>`
	position: relative;
	height: 0;
	padding-bottom: ${ASPECT_RATIO.widescreen};
	overflow: hidden;
	box-shadow: 0 30px 27px rgba(0, 0, 0, 0.09);
	${({ theme: { palette, tokens } }) => css`
		border-radius: ${tokens.borderRadius["3xl"]};
		background: ${palette.freeze[1000]};
	`};
`;

const StyledVideo = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const StyledVideoFooter = styled.div<PropsWithTheme>`
	padding-bottom: var(--spacing-l);
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	${({ theme: { palette, tokens } }) => css`
		background: ${palette.freeze[1000]};
		border-bottom-right-radius: ${tokens.borderRadius["3xl"]};
		border-bottom-left-radius: ${tokens.borderRadius["3xl"]};
	`};
`;

const Video: React.FC<VideoProps> = ({ videoId, type }) => {
	const mounted = useMounted();
	const src = getVideoLink({ videoId, type });
	return (
		<>
			<Row>
				<Column s={0} l={1} />
				<Column l={10}>
					<StyledVideoWrapper>
						{mounted && (
							<StyledVideo
								allowFullScreen
								src={src}
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								frameBorder="0"
								height="315"
								width="560"
							/>
						)}
					</StyledVideoWrapper>
				</Column>
				<Column s={0} l={1} />
			</Row>
			<Hidden m l>
				<Row>
					<Column m={1} l={2} />
					<Column m={6} l={8}>
						<StyledVideoFooter />
					</Column>
				</Row>
			</Hidden>
		</>
	);
};

export default Video;
