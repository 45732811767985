import React from "react";

import { SplideTrack } from "@splidejs/react-splide";
import type { Options as SplideOptions } from "@splidejs/splide";
import { useTranslation } from "next-i18next";

import Link from "next/link";
import { useRouter } from "next/router";

import { PriceLabel } from "@/components/contentful/components/property-list/components";
import {
	StyledControls,
	StyledHoverLink,
	StyledImage,
	StyledSlideContent,
	StyledSplide,
	StyledSplideSlide,
} from "@/components/contentful/components/property-list/styled";
import { Stage } from "@/components/grid";
import { SlideshowButton } from "@/components/splide";
import { Typography } from "@/components/typography/typography";
import dirs from "@/contentful/content-model/dirs";
import { Dirs } from "@/contentful/content-model/types";
import { Icon } from "@/design-system/atoms/icons";
import { TypographyVariant } from "@/theme";
import type { Property } from "@/types/contentful-api";
import "@splidejs/splide/dist/css/splide-core.min.css";
import { PropertyOptions } from "@/types/property";

interface PropertyListSliderProps {
	properties: Property[];
}

const SLIDE_HEIGHT = 429;
const SLIDE_WIDTH = 327;

const splideOptions: SplideOptions = {
	fixedHeight: SLIDE_HEIGHT,
	fixedWidth: SLIDE_WIDTH,
	width: "100vw",
	focus: "center",
	gap: "var(--spacing-xs)",
	keyboard: "focused",
	lazyLoad: "sequential",
	type: "loop",
};

export const PropertyListSlider: React.VFC<PropertyListSliderProps> = ({ properties }) => {
	const { t } = useTranslation(["slideshow"]);
	const { locale } = useRouter();

	return (
		<Stage>
			<StyledSplide
				hasTrack={false}
				options={{
					...splideOptions,
					i18n: {
						prev: t("slideshow:prev-property"),
						next: t("slideshow:next-property"),
						slidex: t("slideshow:go-to-property-x"),
					},
				}}
			>
				<SplideTrack>
					{properties
						.filter(Boolean)
						.filter(item => Boolean(item.exportedPropertyData))
						.filter(item => Boolean(item.featuredImagePortrait ?? item.featuredImage))
						.map(
							({
								sys: { id },
								entryLink: city,
								featuredImage,
								featuredImagePortrait,
								salesStatus,
								options,
								hidePrice,
								exportedPropertyData: {
									data: { headline, price },
								},
							}) => {
								const isProject = options?.includes(PropertyOptions.Project);

								const propertyUrl = `/${
									dirs[Dirs.property].dir[locale] as string
								}/${id}`;
								const { url, description } = featuredImagePortrait ?? featuredImage;
								const contentfulImage = `${url}?fm=webp&w=${SLIDE_WIDTH}&h=${SLIDE_HEIGHT}&fit=fill&q=70`;

								return (
									<StyledSplideSlide key={id}>
										<Link passHref href={propertyUrl} locale={locale}>
											<StyledHoverLink>
												<StyledImage
													data-splide-lazy={contentfulImage}
													height={SLIDE_HEIGHT}
													width={SLIDE_WIDTH}
													alt={description}
												/>
												<StyledSlideContent>
													<Typography tight>
														{city && `${city.breadcrumb} - `}
														<PriceLabel
															price={price}
															salesStatus={salesStatus}
															hidePrice={hidePrice}
															isProject={isProject}
														/>
													</Typography>
													<Typography
														tight
														component="h3"
														variant={TypographyVariant.headlineSerifSM}
													>
														{headline}
													</Typography>
												</StyledSlideContent>
											</StyledHoverLink>
										</Link>
									</StyledSplideSlide>
								);
							}
						)}
				</SplideTrack>
				<StyledControls className="splide__arrows">
					<SlideshowButton className="splide__arrow splide__arrow--prev">
						<Icon icon="chevronLeft" />
					</SlideshowButton>
					<SlideshowButton className="splide__arrow splide__arrow--next">
						<Icon icon="chevronRight" />
					</SlideshowButton>
				</StyledControls>
			</StyledSplide>
		</Stage>
	);
};
