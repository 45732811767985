import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Dialog from "@material-ui/core/Dialog";
import { SplideSlide, SplideTrack } from "@splidejs/react-splide";

import { AbsoluteIconButton } from "@/design-system/atoms/button";
import type { PropsWithTheme } from "@/theme";

export const StyledDialog = styled(Dialog) <PropsWithTheme>`
	overflow: hidden;
	${({ theme: { palette } }) => css`
		.MuiPaper-root {
			background-color: ${palette.freeze[1000]};
		}
	`};
`;

export const StyledCloseButton = styled(AbsoluteIconButton) <PropsWithTheme<HTMLButtonElement>>`
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.3);
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[0]};
	`};
`;

export const StyledTrack = styled(SplideTrack)`
	padding-bottom: 10px;
`;

export const StyledThumb = styled(SplideSlide) <
	PropsWithTheme & {
		selected?: boolean;
	}
>`
	width: 100px;
	height: 70px;
	border: 4px solid transparent;
	cursor: pointer;

	&:focus {
		outline: 0;
	}

	${({ theme: { palette }, selected }) => css`
		border-color: ${selected ? palette.blue[500] : "initial"};

		&:focus-within {
			border-color: ${palette.blue[500]};
		}
	`};
`;
