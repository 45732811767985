import React from "react";

import Image from "next/image";

import { ActionButtonLink } from "@/components/action-button-link";
import type { ImageTextComponentProps } from "@/components/contentful/types";
import { Column, Row } from "@/components/grid";
import { Spacer } from "@/components/layout/components";
import { Md } from "@/components/markdown";
import { Typography } from "@/components/typography/typography";
import { FontWeight, TypographyVariant } from "@/theme";
import { imageLoaderFaceFill } from "@/utils/image";

import { Hidden, StyledImageWrapper } from "../styled";

export const PlainImageText: React.FC<ImageTextComponentProps> = ({
	description,
	headline,
	subtitle,
	flip,
	navigationItem,
	dark,
	imagesCollection: {
		items: [image],
	},
}) => {
	return (
		<Row>
			<Column l={5}>
				<Hidden l>
					<Typography component="h5" weight={FontWeight.medium}>
						{subtitle}
					</Typography>
					<Typography tight component="h2" variant={TypographyVariant.headlineSerifLG}>
						{headline}
					</Typography>
				</Hidden>
				<Hidden s m>
					<Typography component="h2" variant={TypographyVariant.headlineSerifLG}>
						{headline}
					</Typography>
				</Hidden>
				<Md source={description} />
				<ActionButtonLink navigationItem={navigationItem} dark={dark} />
				{navigationItem && (
					<Hidden s m>
						<Spacer spacing="s" />
					</Hidden>
				)}
			</Column>
			<Column l={0}>
				{image && (
					<StyledImageWrapper>
						<Image
							loader={imageLoaderFaceFill}
							src={image.url}
							alt={image.description ?? ""}
							layout="responsive"
							width={image.width}
							height={image.height}
							quality={40}
							sizes="100vw"
							loading="lazy"
						/>
					</StyledImageWrapper>
				)}
			</Column>
			<>
				<Column s={0} l={1} order={flip ? -1 : undefined} />
				<Column s={0} l={6} order={flip ? -2 : undefined}>
					{image && (
						<StyledImageWrapper>
							<Image
								loader={imageLoaderFaceFill}
								src={image.url}
								alt={image.description ?? ""}
								layout="responsive"
								width={image.width}
								height={image.height}
								quality={30}
								sizes="50vw"
								loading="lazy"
							/>
						</StyledImageWrapper>
					)}
				</Column>
			</>
		</Row>
	);
};
