import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Row } from "@/components/grid";
import { CenterColumn } from "@/components/grid/extensions";
import { Typography } from "@/components/typography/typography";
import type { PropsWithTheme } from "@/theme";

// Account for extra bottom margin on last Grid Box item(s)
export const StyledRow = styled(Row)<PropsWithTheme>`
	margin-bottom: calc(var(--spacing-xxs) * -1);
`;

export const StyledBox = styled.div<PropsWithTheme>`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	height: 90px;
	margin-bottom: var(--spacing-xxs);
	padding: var(--spacing-xxs);
	border: 1px solid silver;
	border-radius: 8px;
`;

export const StyledLink = styled.a<PropsWithTheme>`
	width: 100%;
`;

export const StyledImageWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	filter: grayscale(100%);
`;

export const IconWrapper = styled.div<PropsWithTheme>`
	width: 70px;
	max-width: 100%;
	height: 70px;
	margin: auto;
`;

export const Card = styled.div<PropsWithTheme>`
	display: flex;
	flex: 1;
	margin: var(--spacing-xxxs) 0;
`;

export const CardContent = styled.div<PropsWithTheme>`
	flex: 1;
	width: 100%;
	padding: var(--spacing-xs) var(--spacing-xxs) var(--spacing-xs) var(--spacing-xs);
	border-radius: 10px;
	box-shadow: inset 0 0 0 1px currentColor;
`;

export const StyledInfoTextBox = styled(CenterColumn)<PropsWithTheme>`
	margin-top: var(--spacing-xxs);
	padding: var(--spacing-m) var(--spacing-s);
	${({ theme: { palette } }) => css`
		box-shadow: inset 0 0 0 1px ${palette.freeze[300]};
	`};
`;

export const StyleBorderlessInfoTextRow = styled(Row)<PropsWithTheme>`
	row-gap: var(--spacing-xs);
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			row-gap: var(--spacing-s);
		}
	`};
`;

export const StyledTypography = styled(Typography)<PropsWithTheme>`
	${({ theme: { palette }, dark }) => css`
		color: ${dark ? palette.freeze[0] : palette.freeze[1000]};
	`};
`;
