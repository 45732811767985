import React from "react";

import { Newsletter } from "@/components/contentful/components/newsletter";
import { Column, Grid, Row } from "@/components/grid";
import type {
	NewsletterSubscription as NewsletterSubscriptionSlotProps,
	TextCollection,
} from "@/types/contentful-api";

export const NewsletterSlot: React.FC<
	{ textCollection: TextCollection } & NewsletterSubscriptionSlotProps
> = props => (
	<Grid>
		<Row>
			<Column>
				<Newsletter {...props} />
			</Column>
		</Row>
	</Grid>
);
