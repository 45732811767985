export interface Collection<T> {
	items: T[];
}

export enum PropertyOptions {
	Project = "Project",
	Selected = "Selected",
	Listed = "Listed",
	Pinned = "Pinned",
	Highlight = "Highlight",
	Investment = "Investment",
}

export enum EnergyPerformanceCertType {
	CONSUMER = "energy-cert-consumer",
	REQUIREMENT = "energy-cert-requirement",
	NOT_REQUIRED = "energy-cert-not-required",
}

export enum HeatingType {
	ELECTRIC_HEATING = "ELECTRIC_HEATING",
	NATURAL_GAS = "NATURAL_GAS",
	DISTRICT_HEATING = "DISTRICT_HEATING",
	GAS_HEATING = "GAS_HEATING",
	STOVE_HEATING = "STOVE_HEATING",
	OIL_HEATING = "OIL_HEATING",
	WOOD_PELLET_HEATING = "WOOD_PELLET_HEATING",
	SOLAR_HEATING = "SOLAR_HEATING",
	CENTRAL_HEATING = "CENTRAL_HEATING",
	HEAT_PUMP = "HEAT_PUMP",
}

export enum EnergySource {
	ELECTRICITY = "ELECTRICITY",
	NATURAL_GAS = "NATURAL_GAS",
	GEOTHERMAL_ENERGY = "GEOTHERMAL_ENERGY",
	COMBINED_HEAT_AND_POWER_RENEWABLE_ENERGY = "COMBINED_HEAT_AND_POWER_RENEWABLE_ENERGY",
	DISTRICT_HEATING = "DISTRICT_HEATING",
	OIL = "OIL",
	WOODEN_PELLET_HEATING = "WOODEN_PELLET_HEATING",
	ENVIRONMENTAL_THERMAL = "ENVIRONMENTAL_THERMAL",
}

export enum EnergyEfficiencyClassDE {
	A_PLUS = "A+",
	A = "A",
	B = "B",
	C = "C",
	D = "D",
	E = "E",
	F = "F",
	G = "G",
	H = "H",
}

export enum EnergyEfficiencyClassES {
	A = "A",
	B = "B",
	C = "C",
	D = "D",
	E = "E",
	F = "F",
	G = "G",
	EXEMPT = "exempt",
	IN_PROCESS = "in_process",
	UNKNOWN = "unknown",
}

export enum ApartmentType {
	ATTIC = "ATTIC",
	LOFT = "LOFT",
	MAISONETTE = "MAISONETTE",
	PENTHOUSE = "PENTHOUSE",
	TERRACE = "TERRACE",
	GROUNDFLOOR = "GROUNDFLOOR",
	FLAT = "FLAT",
	MEZZANINE = "MEZZANINE",
	SOUTERRAIN = "SOUTERRAIN",
	OTHER = "OTHER",
}

export enum HouseType {
	BUNGALOW = "BUNGALOW",
	FAMILY = "FAMILY",
	MULTI_FAMILY = "MULTI_FAMILY",
	ROW_END = "ROW_END",
	ROW_MIDDLE = "ROW_MIDDLE",
	SEMI_DETACHED = "SEMI_DETACHED",
	VILLA = "VILLA",
	OTHER = "OTHER",
}
