import React, { useRef } from "react";

import type { Splide } from "@splidejs/react-splide";
import { SplideSlide, SplideTrack } from "@splidejs/react-splide";
import type { Options as SplideOptions } from "@splidejs/splide";
import { useTranslation } from "next-i18next";

import { Column, Grid, Row } from "@/components/grid";
import { StandaloneQuote } from "@/components/quote";
import { Icon } from "@/design-system/atoms/icons";
import type { Testimonials as TestimonialsSlotProps } from "@/types/contentful-api";

import "@splidejs/splide/dist/css/splide-core.min.css";
import { StyledSlideshowButton, StyledSplide } from "./styled";

const splideOptions: SplideOptions = {
	type: "loop",
	arrows: false,
	gap: "var(--spacing-xxs)",
};

export const StandaloneTestimonials: React.VFC<TestimonialsSlotProps> = props => {
	const ref = useRef<Splide>();
	const { t } = useTranslation("slideshow");

	const items = props.itemsCollection.items;

	return items.length > 1 ? (
		<StyledSplide
			ref={ref}
			hasTrack={false}
			options={{
				...splideOptions,
				i18n: {
					prev: t("slideshow:prev-property"),
					next: t("slideshow:next-property"),
					slidex: t("slideshow:go-to-property-x"),
				},
			}}
		>
			<Grid>
				<Row>
					<Column s={0} l={2}>
						<StyledSlideshowButton
							onClick={() => {
								ref?.current.go("<");
							}}
						>
							<Icon icon="chevronLeft" />
						</StyledSlideshowButton>
					</Column>
					<Column l={8}>
						<SplideTrack>
							{items.map(item => (
								<SplideSlide key={item.sys.id}>
									<StandaloneQuote {...item} />
								</SplideSlide>
							))}
						</SplideTrack>
					</Column>
					<Column s={0} l={1} />
					<Column s={0} l={1}>
						<StyledSlideshowButton
							onClick={() => {
								ref?.current.go(">");
							}}
						>
							<Icon icon="chevronRight" />
						</StyledSlideshowButton>
					</Column>
				</Row>
			</Grid>
		</StyledSplide>
	) : (
		<Grid>
			<Row>
				<Column s={0} l={2} />
				<Column l={8}>{items?.[0] && <StandaloneQuote {...items[0]} />}</Column>
			</Row>
		</Grid>
	);
};
