import React from "react";

import {
	IconWrapper,
	StyleBorderlessInfoTextRow,
	StyledTypography,
} from "@/components/boxes/styled";
import type { BoxesComponentProps } from "@/components/boxes/types";
import { BreakLines } from "@/components/break-lines";
import { Column, Hidden, Row } from "@/components/grid";
import { Spacer } from "@/components/layout/components";
import { Typography } from "@/components/typography/typography";
import { Illustration } from "@/illustrations";
import { TypographyVariant } from "@/theme";
import type { IconText } from "@/types/contentful-api";

export const BorderlessInfoTextBoxes: React.VFC<BoxesComponentProps<IconText[]>> = ({
	dark,
	items,
}) => {
	return (
		<StyleBorderlessInfoTextRow>
			{items.map(item => {
				const { icon, headline, description } = item;
				return (
					<Column key={item.sys.id} l={4}>
						<Row>
							<Column s={1}>
								<Hidden s m>
									<Spacer spacing="xs" />
								</Hidden>
								<IconWrapper>
									<Illustration illustration={icon} />
								</IconWrapper>
							</Column>
							<Column s={3} m={6} l={4}>
								<Row>
									<Column l={3}>
										<Typography
											component="h3"
											variant={TypographyVariant.headlineSansXXS}
										>
											<BreakLines text={headline} />
										</Typography>
										<StyledTypography top dark={dark}>
											<BreakLines text={description} />
										</StyledTypography>
									</Column>
									<Hidden s m>
										<Column l={1} />
									</Hidden>
								</Row>
							</Column>
						</Row>
					</Column>
				);
			})}
		</StyleBorderlessInfoTextRow>
	);
};
