import React from "react";

import renderJsxToHtmlString from "preact-render-to-string";

import { Column, Grid, Row } from "@/components/grid";
import { Spacer } from "@/components/layout/components";
import { RawMarkdown } from "@/components/markdown";
import { MarkdownAccordion } from "@/components/markdown-accordion";
import { Background, SlotHeader } from "@/components/slot/components";
import { StructuredData } from "@/components/structured-data";
import { BackgroundColor } from "@/theme";
import type { List as FaqSlotProps } from "@/types/contentful-api";

export const FaqSlot = (props: FaqSlotProps) => {
	const {
		sys: { id },
		headline,
		subtitle,
		backgroundColor,
		structuredData,
		itemsCollection,
	} = props;
	const items = itemsCollection.items.filter(Boolean);

	return (
		<>
			{structuredData && items.length > 0 && (
				<StructuredData
					id={id}
					data={{
						"@context": "https://schema.org",
						"@type": "FAQPage",
						mainEntity: itemsCollection.items.map(item => ({
							"@type": "Question",
							name: item?.headline,
							acceptedAnswer: {
								"@type": "Answer",
								text: renderJsxToHtmlString(
									<RawMarkdown source={item?.description} />
								),
							},
						})),
					}}
				/>
			)}
			<Background
				backgroundColor={(backgroundColor as BackgroundColor) ?? BackgroundColor.white}
			>
				<Grid>
					<Row>
						<Column l={2} />
						<Column l={8}>
							<SlotHeader headline={headline} subtitle={subtitle} />
						</Column>
					</Row>
					<Row>
						<Column l={2} />
						<Column l={8}>
							{items.map(faq => {
								const id = faq?.sys.id;
								return (
									<React.Fragment key={id}>
										<MarkdownAccordion id={id} {...faq} />
										<Spacer spacing="xs" />
									</React.Fragment>
								);
							})}
						</Column>
					</Row>
				</Grid>
			</Background>
		</>
	);
};
